import { Box, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';

const StepNumber = ({ number }: { number: number }) => (
  <Box
    width="36px"
    minWidth="36px"
    height="36px"
    borderRadius="50%"
    backgroundColor="green.500"
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    zIndex="1"
    borderWidth="5px"
    borderColor="gray.800"
  >
    {number}
  </Box>
);

const IndicatorLine = () => (
  <Box
    position="absolute"
    top="0"
    left="18px"
    height="calc(100% + 80px)"
    width="1px"
    backgroundColor="gray.500"
    marginInlineStart="0 !important"
  />
);

const steppedData = [
  {
    title: 'Download',
    content: (
      <>
        <Text>Download and launch Waves Keeper, then click Get Started.</Text>
        <Image src="/images/getting-started/download.png" />
        <Text>Read the Terms and Conditions and click Accept.</Text>
      </>
    ),
  },
  {
    title: 'Create Waves Keeper Password',
    content: (
      <>
        <Text>
          The following pop-up will appear. Protect your account with Keeper
          password.
        </Text>
        <Image src="/images/getting-started/password.png" />
        <Text>
          The password you entered will be stored locally. If you change device
          or lose your password, you will have to repeat the process of adding
          accounts to Waves Keeper. The Waves blockchain does not store your
          passwords.
        </Text>
        <Text>
          After entering the password and confirmation, click{' '}
          <strong>Continue.</strong>
        </Text>
      </>
    ),
  },
  {
    title: 'Create Account',
    content: (
      <>
        <Text>
          Now you need to create a new account or import an existing account.
        </Text>
        <Image src="/images/getting-started/new-account.png" />
        <Text>
          Click
          <strong>Create a New Account.</strong>
        </Text>
      </>
    ),
  },
  {
    title: 'Creating Account',
    content: (
      <>
        <Text>
          Select the address avatar and click <strong>Continue</strong>.
        </Text>
        <Image src="/images/getting-started/creating-account.png" />
      </>
    ),
  },
  {
    title: 'Setting Account Name',
    content: (
      <>
        <Text>
          Enter the account name and click <strong>Create backup</strong>. This
          account name can be anything and it will help you tell the difference
          between the accounts in Waves Keeper
        </Text>
        <Image src="/images/getting-started/creating-account.png" />
      </>
    ),
  },
  {
    title: 'Backup phrase',
    content: (
      <>
        <Text>
          Please carefully write down these 15 words (seed phrase) on paper or
          copy them to paste and save in a text file. You will need this phrase
          when you want to add your Waves account to another site ro app. After
          that click <strong>Continue</strong>
        </Text>
        <Image src="/images/getting-started/creating-account.png" />
      </>
    ),
  },
  {
    title: 'Confirm phrase',
    content: (
      <>
        <Text>
          Here you can shortly copy address, scan QR code which contains your
          deposit Waves address or find private data of your account by clicking
          the shield icon
        </Text>
        <Image src="/images/getting-started/creating-account.png" />
      </>
    ),
  },
  {
    title: "You're all set!",
    content: (
      <>
        <Text>
          Confirm backup phrase by placing each word in the correct order and
          click <strong>Confirm.</strong>
        </Text>
        <Image src="/images/getting-started/wallet.png" />
      </>
    ),
  },
];

const GettingStarted = () => {
  return (
    <VStack spacing="20" mb="20" alignItems="flex-start">
      {steppedData.map(({ title, content }, i) => (
        <HStack
          key={title}
          alignItems="flex-start"
          spacing="6"
          position="relative"
        >
          <StepNumber number={i + 1} />
          <VStack alignItems="flex-start" width="500px" spacing="6">
            <Heading
              size="md"
              mt="2"
              borderBottomWidth="2px"
              borderBottomColor="gray.500"
            >
              {title}
            </Heading>
            <VStack alignItems="flex-start" spacing="4">
              {content}
            </VStack>
          </VStack>
          <IndicatorLine />
        </HStack>
      ))}
    </VStack>
  );
};

export default GettingStarted;
