import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import applicationReducer from './slices/application';
import dialogReducer from './slices/dialog';
import nftReducer from './slices/nft';
import notificationsReducer from './slices/notifications';
import { APP_STATE_LS_KEY } from '../constants';
import { getFroLocalStorage } from '../helpers/application';

// @ts-ignore
const applicationStateMiddleware = store => next => action => {
  setTimeout(() => {
    if (action.type === 'application/setAccordionState') {
      const { application } = store.getState();
      const { accordions } = application;
      localStorage.setItem(
        APP_STATE_LS_KEY,
        JSON.stringify({
          accordions,
        }),
      );
    }
  });
  return next(action);
};

export function makeStore() {
  return configureStore({
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(applicationStateMiddleware),
    preloadedState: {
      application: {
        ...getFroLocalStorage(APP_STATE_LS_KEY),
        appLoading: true,
      },
    },
    reducer: {
      application: applicationReducer,
      dialog: dialogReducer,
      nft: nftReducer,
      notifications: notificationsReducer,
    },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
