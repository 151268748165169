import React from 'react';
import { Box, Image as ImageWrapper } from '@chakra-ui/react';
import { hashToColor } from '../../hooks/canvas/util';
import { ImageProps } from '@chakra-ui/image/src/image';

import { BURNED, EVOLVE, evolvePlaceholders } from '../../constants';

type BlankSquareProps = {
  id: string;
};

type Props = ImageProps & {
  gen?: number;
  id: string;
  image?: string;
  status?: string;
};

const BlankSquare = ({ id }: BlankSquareProps) => {
  const backgroundColor = `#${hashToColor(id ?? '')}`;
  return (
    <Box width="100%" height={0} pb="100%" backgroundColor={backgroundColor} />
  );
};

const NftImage = ({ gen = 1, id, image, status }: Props) => {
  if (status === EVOLVE || status === BURNED) {
    return (
      <ImageWrapper
        src={evolvePlaceholders[gen - 1]}
        loading="lazy"
        objectFit="contain"
        objectPosition="top"
        width="100%"
      />
    );
  }
  if (image) {
    return (
      <ImageWrapper
        src={image}
        alt=""
        loading="lazy"
        objectFit="contain"
        objectPosition="top"
        width="100%"
      />
    );
  }
  return <BlankSquare id={id} />;
};

export default NftImage;
