import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import { useQuery } from 'react-query';
import { SquareMetaResponse } from '../../types';
import { SQUARE_META_ENDPOINT } from '../../constants';
import { getSquareMeta } from '../../services/api';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { startCase } from 'lodash';
import { getRandomColorScheme } from '../../helpers/squares';

type MetaCardProps = {
  attribute: string;
  color?: string;
  featureColor: string;
  usagePercentage?: number;
  variant: string;
};

const MetaCard = ({
  attribute,
  featureColor,
  usagePercentage,
  variant,
}: MetaCardProps) => {
  if (usagePercentage === 1) {
    return null;
  }

  return (
    <VStack
      borderRadius="base"
      border="solid 2px"
      borderColor={`${featureColor}.800`}
      backgroundColor={`${featureColor}.900`}
      p="0"
      pb="2"
      width="100%"
      spacing="1"
      alignItems="center"
      textAlign="center"
    >
      <Heading
        p="2"
        pb={0}
        fontSize="12px"
        width="100%"
        fontWeight="normal"
        textTransform="uppercase"
        color={`${featureColor}.300`}
      >
        {startCase(attribute.toLowerCase())}
      </Heading>
      <Text as={Box} width="100%" px="2" title={startCase(variant)} isTruncated>
        {startCase(variant)}
      </Text>
      {/*{usagePercentage && (*/}
      {/*  <Text*/}
      {/*    as={Box}*/}
      {/*    width="100%"*/}
      {/*    px="2"*/}
      {/*    fontSize="sm"*/}
      {/*    title={startCase(variant)}*/}
      {/*    opacity="0.6"*/}
      {/*    isTruncated*/}
      {/*  >*/}
      {/*    {Math.round(usagePercentage * 100)}% Have this trait*/}
      {/*  </Text>*/}
      {/*)}*/}
    </VStack>
  );
};

const Traits = () => {
  const { coords, gen, status } = useSelector(getNftDataByActiveId);
  const featureColor = useMemo(() => getRandomColorScheme(), []);
  const { data } = useQuery<SquareMetaResponse>(
    [SQUARE_META_ENDPOINT, coords, gen, status],
    () => getSquareMeta(coords, gen),
  );

  if (!data) {
    return null;
  }

  console.log('data', data.traits);

  return (
    <VStack
      p="4"
      backgroundColor="gray.800"
      spacing="6"
      alignItems="flex-start"
      width="100%"
    >
      {/*<Heading>{startCase(data.rarity.rarity)}</Heading>*/}

      <VStack alignItems="flex-start">
        <Heading>Traits</Heading>
        <Heading size="xs">
          (Rarity will be assigned when more of the grid has been minted)
        </Heading>
      </VStack>

      <Box
        display="grid"
        width="100%"
        gridTemplateColumns={{
          base: 'repeat(2, minmax(10px, 1fr))',
          sm: 'repeat(4, minmax(50px, 1fr))',
        }}
        gridGap="4"
      >
        {data.traits.map(
          ({ attribute, color, key, usagePercentage, variant }) => (
            <MetaCard
              attribute={attribute}
              color={color}
              featureColor={featureColor}
              key={key}
              usagePercentage={usagePercentage}
              variant={variant}
            />
          ),
        )}
      </Box>
    </VStack>
  );
};

export default Traits;
