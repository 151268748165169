import React, { useContext } from 'react';
import { NftData } from 'types';
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { rowColToIndex } from '../../hooks/canvas/util';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { defaultScaleLevel } from '../../hooks/canvas/useCanvasViewport';
import { CanvasContext } from '../../hooks/canvas';
import { SQUARE } from '../../constants';
import NftImage from './Image';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import TokenDisplay from '../Waves/TokenDisplay';
import { normalizeWaves } from '../../helpers/blockchain';
import { hasOwner } from '../../helpers/application';
import NftStatusIcon from './StatusIcon';

type Props = {
  data: NftData;
};

const IconWrapper = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`;

const StyledNftImage = styled(NftImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledText = styled(Text)`
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
`;
StyledText.defaultProps = {
  fontSize: 'sm',
  color: 'gray.500',
};

const NftCard = ({ data }: Props) => {
  const { navigateToIndex } = useContext(CanvasContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { created, id, image, x, y, owner, saleAmount } = data;

  const handleClick = () => {
    if (y && x) {
      const index = rowColToIndex(
        parseInt(y.toString()),
        parseInt(x.toString()),
      );
      const scale = parseInt(
        searchParams.get('scale')?.toString() ?? defaultScaleLevel.toString(),
      );
      navigateToIndex({ index });

      setTimeout(() =>
        navigate(`${SQUARE}/${x},${y}?center=${index}&scale=${scale}`),
      );
    }
  };

  return (
    <VStack
      alignItems="flex-start"
      backgroundColor="gray.800"
      borderRadius="md"
      onClick={handleClick}
      width="100%"
      cursor="pointer"
      overflow="hidden"
      spacing="0"
      transitionProperty="all"
      transitionDuration="0.3s"
      transitionTimingFunction="ease-out"
      _hover={{ shadow: '2xl', transform: 'scale(1.02) translateZ(0)' }}
    >
      <ImageWrapper>
        <NftStatusIcon as={IconWrapper} owner={owner} saleAmount={saleAmount} />
        <StyledNftImage
          boxSize="200px"
          objectFit="cover"
          id={id}
          image={image}
        />
      </ImageWrapper>
      <VStack
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        p="4"
        borderBottomLeftRadius="md"
        borderBottomRightRadius="md"
        spacing="1"
      >
        <Heading size="md" pb="2">
          {x},{y}
        </Heading>
        {created && (
          <HStack width="100%">
            <StyledText>Created:</StyledText>
            <StyledText>
              {format(new Date(parseInt(created.toString())), 'MMM dd yyy')}
            </StyledText>
          </HStack>
        )}
        {hasOwner(owner) && (
          <HStack width="100%">
            <StyledText>Owner:</StyledText>
            <StyledText isTruncated>{owner}</StyledText>
          </HStack>
        )}
        {saleAmount && (
          <HStack width="100%" alignItems="center">
            <StyledText>For&nbsp;sale:</StyledText>
            <StyledText>
              <TokenDisplay
                fontSize="sm"
                showConversion={false}
                waves={normalizeWaves(saleAmount)}
              />
            </StyledText>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default NftCard;
