import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationState, AppState, WavesKeeperData } from 'types';

const initialState: ApplicationState = {
  accordions: {},
  loading: true,
  height: 0,
  page: null,
  user: null,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    clearUser: (state: ApplicationState) => {
      state.user = null;
    },
    setAccordionState: (
      state: ApplicationState,
      action: PayloadAction<{ name: string; isOpen: boolean }>,
    ) => {
      state.accordions = {
        ...(state.accordions ?? {}),
        [action.payload.name]: action.payload.isOpen,
      };
    },
    setAppLoading: (
      state: ApplicationState,
      action: PayloadAction<boolean>,
    ) => {
      state.loading = action.payload;
    },
    setUser: (
      state: ApplicationState,
      action: PayloadAction<WavesKeeperData>,
    ) => {
      if (action?.payload?.account) {
        state.user = action.payload.account;
      }
    },
    setHeight: (state: ApplicationState, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
    setPage: (state: ApplicationState, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
  },
});

export const {
  clearUser,
  setAccordionState,
  setHeight,
  setAppLoading,
  setUser,
} = applicationSlice.actions;

export const getAccordionByName =
  (name: string, defaultOpen: boolean = true) =>
  (state: AppState) =>
    state.application.accordions?.[name] ?? defaultOpen;
export const getCurrentHeight = (state: AppState) => state.application.height;
export const getAppLoading = (state: AppState) =>
  state.application?.loading ?? true;
export const getUser = (state: AppState) => state.application.user;
export const getUserAddress = (state: AppState) =>
  state.application.user?.address ?? '';

export const getPage = (state: AppState) => state.application?.page;
export const applicationStateActions = applicationSlice.actions;
export default applicationSlice.reducer;
