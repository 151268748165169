import { useEffect, useRef, useState } from 'react';
import {
  BUY_FROM_HOLDINGS_FUNCTION,
  DAPP_ADDRESS,
  HOLDINGS_DAPP_ADDRESS,
  INTEGER,
  PROMOTION_PURCHASE,
  PURCHASE_FUNCTION,
  PUT_FOR_SALE_FUNCTION,
  REVOKE_FOR_SALE_FUNCTION,
  STRING,
} from './constants';
import { buildBurnParams, buildInvokeScriptParams } from './helper';
import {
  Box,
  Button,
  Heading,
  IconButton,
  Text,
  ToastId,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { RiCloseFill } from 'react-icons/ri';
import { WavesKeeperData } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUser,
  getUserAddress,
  setUser,
} from '../../redux/slices/application';
import { setSquareStatus, updateFromBlockchain } from '../../services/api';
import { ACTIVE, BLOCKCHAIN, BUSY } from '../../constants';
import { getFullWaves, normalizeWaves } from '../../helpers/blockchain';
import { WavesTransaction } from '../../../../state-machine/src/@types';
import { getNftDictData } from '../../redux/slices/nft';
import { getUserHandle } from '../../services/blockchain';

const authData = {
  data: DAPP_ADDRESS,
  name: 'NFT Grid',
};

type ErrorMessageProps = {
  onClose: () => void;
};

type InvokeFunctionDefault = {
  coords: string;
  setInitialStatus?: boolean;
};

type GiftSquare = {
  x: string;
  y: string;
  userAddress: string;
};

type Purchase = InvokeFunctionDefault & {
  x: number;
  y: number;
  userText?: string;
};

type Evolve = InvokeFunctionDefault & {
  assetId: string;
};

type PutUpForSale = InvokeFunctionDefault & {
  assetId: string;
  amount: string | number;
  coords: string;
};

type PurchaseForSale = InvokeFunctionDefault & {
  assetId: string;
  amount: number;
  setInitialStatus?: boolean;
  userText?: string;
};

type RevokeForSale = InvokeFunctionDefault & {
  assetId: string;
};

let updateTimeout: ReturnType<typeof setTimeout>;

const NoWavesErrorMessage = ({ onClose }: ErrorMessageProps) => (
  <VStack
    alignItems="flex-start"
    backgroundColor="orange.200"
    ml="5"
    p="4"
    position="relative"
  >
    <Heading size="sm" color="gray.900">
      Waves Keeper not detected
    </Heading>
    <Text color="gray.900">
      In order to buy and sell from the Grid Gang, you need to have Waves Keeper
      setup in your browser.
    </Text>
    <Button colorScheme="red" size="sm">
      Setup Waves Keeper
    </Button>
    <Box
      position="absolute"
      top="0"
      right="3"
      backgroundColor="yellow.500"
      borderRadius="md"
    >
      <IconButton
        aria-label="Search database"
        icon={<RiCloseFill color="#333" />}
        onClick={onClose}
        size="sm"
      />
    </Box>
  </VStack>
);

const useWaves = () => {
  const dispatch = useDispatch();
  const errorRef = useRef<ToastId>();
  const userAddress = useSelector(getUserAddress);
  const user = useSelector(getUser);
  const nftDict = useSelector(getNftDictData);
  const [hasWavesKeeper, setHasWavesKeeper] = useState(true);
  const toast = useToast();

  useEffect(() => {
    if (hasWavesKeeper) {
      // @ts-ignore
      window.WavesKeeper?.on('update', (keeperState: WavesKeeperData) => {
        dispatch(setUser(keeperState));
      });
    }
  }, [hasWavesKeeper]);

  useEffect(() => {
    const handleWindowLoad = () => {
      // @ts-ignore
      if (!window.WavesKeeper) {
        const retries = 50;
        let tries = 0;

        const checkInterval = setInterval(() => {
          // @ts-ignore
          if (window.WavesKeeper || tries >= retries) {
            // @ts-ignore
            setHasWavesKeeper(!!window.WavesKeeper);
            clearInterval(checkInterval);
          }
          tries++;
        }, 200);
      }
    };

    window.addEventListener('load', handleWindowLoad);

    return () => window.removeEventListener('load', handleWindowLoad);
  }, []);

  const waitForWavesKeeper = async () =>
    new Promise(resolve => {
      // @ts-ignore
      if (!window.WavesKeeper) {
        const retries = 50;
        let tries = 0;

        const checkInterval = setInterval(() => {
          // @ts-ignore
          if (window.WavesKeeper || tries >= retries) {
            // @ts-ignore
            resolve(!!window.WavesKeeper);
          }
          tries++;
        }, 200);
      } else {
        resolve(true);
      }
    });

  const getUserData = async () => {
    const hasKeeper = await waitForWavesKeeper();
    if (!hasKeeper) {
      if (!errorRef?.current) {
        errorRef.current = toast({
          position: 'bottom-left',
          render: () => (
            <NoWavesErrorMessage
              onClose={() => {
                if (errorRef?.current) {
                  toast.close(errorRef.current);
                }
              }}
            />
          ),
          status: 'warning',
          duration: null,
        });
      }
      return null;
    } else {
      // @ts-ignore
      const userData: WavesKeeperData = await window.WavesKeeper.publicState();

      if (userData?.account?.address) {
        userData.account.handle = await getUserHandle(userData.account.address);

        return userData;
      } else {
        await authenticate();
        // @ts-ignore
        return window.WavesKeeper.publicState();
      }
    }
  };

  const authenticate = async () => {
    if (userAddress) {
      return Promise.resolve(userAddress);
    }
    try {
      // @ts-ignore
      const data = await window.WavesKeeper.auth(authData);
      dispatch(setUser(data));
      return Promise.resolve(data);
    } catch (err) {
      console.log('ERR auth', err);
    }
  };

  const batchPurchase = async (coords: string[], userText?: string) => {
    const resolvedUserText = userText || user?.handle || '';
    setSquareStatus({ coords, status: BUSY }).then(() => {});
    await Promise.all(
      coords.map(async coord => {
        const [x, y] = coord.split(',');

        const { assetId, owner, saleAmount } = nftDict[coord];

        if (saleAmount && owner) {
          if (assetId) {
            return purchaseForSale({
              amount: saleAmount,
              assetId,
              coords: coord,
              setInitialStatus: false,
            });
          }
        } else {
          return purchase({
            x: parseInt(x),
            y: parseInt(y),
            coords: coord,
            setInitialStatus: false,
            userText: resolvedUserText,
          });
        }
      }),
    );
  };

  const purchase = ({ x, y, coords, userText }: Purchase) => {
    const resolvedUserText = userText || user?.handle || '';
    return invokeWavesFunction(
      {
        func: PURCHASE_FUNCTION,
        args: [
          { type: STRING, value: x.toString() },
          { type: STRING, value: y.toString() },
          { type: STRING, value: resolvedUserText },
        ],
      },
      coords,
    );
  };

  const evolveSquare = ({ assetId, coords }: Evolve) => {
    return invokeWavesFunction(assetId, coords, true, buildBurnParams);
  };

  const giftSquare = ({ x, y, userAddress }: GiftSquare) => {
    return invokeWavesFunction(
      {
        func: PROMOTION_PURCHASE,
        args: [
          { type: STRING, value: x.toString() },
          { type: STRING, value: y.toString() },
          { type: STRING, value: userAddress },
        ],
        paymentAmount: 0.001,
      },
      `${x},${y}`,
    );
  };

  const putUpForSale = async ({ assetId, amount, coords }: PutUpForSale) =>
    invokeWavesFunction(
      {
        func: PUT_FOR_SALE_FUNCTION,
        args: [
          { type: STRING, value: assetId },
          { type: INTEGER, value: getFullWaves(parseFloat(amount.toString())) },
        ],
        paymentAmount: 1,
        paymentAssetId: assetId,
      },
      coords,
    );

  const purchaseForSale = async ({
    assetId,
    amount,
    coords,
    setInitialStatus = true,
    userText,
  }: PurchaseForSale) => {
    const resolvedUserText = userText || user?.handle || '';
    return invokeWavesFunction(
      {
        dApp: HOLDINGS_DAPP_ADDRESS,
        func: BUY_FROM_HOLDINGS_FUNCTION,
        args: [
          { type: STRING, value: assetId },
          { type: STRING, value: resolvedUserText },
        ],
        paymentAmount: normalizeWaves(amount),
      },
      coords,
      setInitialStatus,
    );
  };

  const revokeForSale = async ({ assetId, coords }: RevokeForSale) =>
    invokeWavesFunction(
      {
        dApp: HOLDINGS_DAPP_ADDRESS,
        func: REVOKE_FOR_SALE_FUNCTION,
        args: [{ type: STRING, value: assetId }],
        paymentAmount: 0.001,
      },
      coords,
    );

  const invokeWavesFunction = async (
    invokeParams: any,
    coords: string | string[],
    setInitialStatus: boolean = true,
    paramsBuilderFunction: (
      arg: any,
      // @ts-ignore
    ) => typeof window.WavesKeeper.TSignTransactionData = buildInvokeScriptParams,
  ): Promise<WavesTransaction | null> => {
    if (setInitialStatus) {
      try {
        setSquareStatus({ coords, status: BUSY });
      } catch (e) {
        return null;
      }
    }

    try {
      await authenticate();
      // @ts-ignore
      const tx = await window.WavesKeeper.signAndPublishTransaction(
        paramsBuilderFunction(invokeParams),
      );
      await setSquareStatus({ coords, status: BLOCKCHAIN });
      clearTimeout(updateTimeout);
      updateTimeout = setTimeout(() => {
        updateFromBlockchain(5000);
      }, 5000);

      return JSON.parse(tx);
    } catch (e) {
      console.log('Error creating transaction', e);
      await setSquareStatus({ coords, status: ACTIVE });
      return null;
    }
  };

  return {
    authenticate,
    evolveSquare,
    batchPurchase,
    getUserData,
    giftSquare,
    hasWavesKeeper,
    purchase,
    purchaseForSale,
    putUpForSale,
    revokeForSale,
  };
};

export default useWaves;
