import { SquareStatus } from '../hooks/crypto/types';
import { BlockchainFunctions } from './waves';

export const MAX_TRANSACTIONS = 24;

export type NftData = {
  assetId?: string;
  coords?: string;
  created?: number;
  gen?: string;
  height?: number;
  id: string;
  image?: string;
  index?: number;
  owner?: string;
  parent?: string | null;
  saleAmount?: number;
  status?: SquareStatus;
  statusSetTimestamp?: number;
  width?: number;
  x?: number;
  y?: number;
};

export type NftDataMeta = {
  attribute: string;
  color: string;
  coord: string;
  gen: string;
  imageHash: string;
  key: string;
  points?: number;
  usagePercentage?: number;
  variant: string;
  x: string;
  y: string;
};

export type AppState = {
  application: ApplicationState;
  dialog: DialogState;
  nft: NftState;
  notifications: NotificationState;
};

export type ApplicationState = {
  accordions: Record<string, boolean>;
  loading: boolean;
  height: number;
  page: string | null;
  user: WavesUser | null;
};

export type DialogState = {
  isOpen: boolean;
  activeId?: number | null;
};

export type NotificationState = {
  waitForIdsInBlock: string[];
};

export type WavesKeeperData = {
  account: WavesUser;
};

export type WavesUser = {
  address: string;
  handle?: string;
};

export type NftDict = { [coords: string]: NftData };

export type NftState = {
  data: NftDict;
  dict: NftDict;
  transactions: NftData[];
};

export type Transaction = {
  amount: string;
  sender: string;
  recipient: string;
  assetId: string;
  height: number;
  timestamp: number;
  functionName: BlockchainFunctions;
  transactionId: string;
  transactionUrl: string;
};
