import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Gallery from 'components/Gallery/Gallery';
import WaitNotification from './components/Notifications/WaitNotification';
import { useEffect } from 'react';
import useWaves from './hooks/crypto/useWaves';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/application';
import PageDialogWrapper from './components/Pages/PageDialogWrapper';
import { CanvasContext, useCanvasManager } from './hooks/canvas';
import SocketProvider from './components/Sockets/SocketProvider';

const Home = () => {
  const dispatch = useDispatch();
  const { getUserData } = useWaves();
  const {
    addForceRenderSquare,
    cleanImageCache,
    init,
    navigateToIndex,
    refreshImageCache,
    zoomIn,
    zoomOut,
  } = useCanvasManager();

  useEffect(() => {
    const getData = async () => {
      const user = await getUserData();
      dispatch(setUser(user));
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCanvasLoad = (canvas: HTMLCanvasElement) => {
    init(canvas);
  };

  const contextData = {
    addForceRenderSquare,
    cleanImageCache,
    navigateToIndex,
    refreshImageCache,
    zoomIn,
    zoomOut,
  };

  return (
    <CanvasContext.Provider value={contextData}>
      <Box
        position="fixed"
        top="3"
        left="3"
        width="150px"
        height="100px"
        pointerEvents="none"
        zIndex={99}
      >
        <Heading
          fontFamily="deadly_advanceregular"
          size="2xl"
          textShadow="1px 1px 10px #000000"
          width="min-content"
          letterSpacing="0.075"
          fontWeight="normal"
        >
          Grid&nbsp;Gang
        </Heading>
      </Box>
      <SocketProvider>
        <Box display="grid" gridTemplateRows="minmax(100px, 1fr)">
          <Gallery
            navigateToIndex={navigateToIndex}
            onCanvasLoad={handleCanvasLoad}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
          <WaitNotification />
        </Box>
        <PageDialogWrapper />
      </SocketProvider>
    </CanvasContext.Provider>
  );
};

export default Home;
