import React, { useEffect, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { NavigateToIndexArgs } from '../../hooks/canvas';
import InfoDialog from '../Dialogs/InfoDialog';
import GalleryControls from '../Global/Controls';
import { AccountMenu } from '../Account';
import styled from '@emotion/styled';
import useSquareRouting from '../../hooks/useSquareRouting';

let initialized = false;

const StyledCanvas = styled.canvas`
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
`;

const Controls = styled(VStack)`
  display: flex;
  position: fixed;
  flex-direction: row;
  top: 16px;
  right: 16px;
  align-items: center;
  gap: 0.5rem;
`;

type Props = {
  navigateToIndex: (args: NavigateToIndexArgs) => void;
  onCanvasLoad: (canvas: HTMLCanvasElement) => void;
  zoomOut: () => void;
  zoomIn: () => void;
};

const Gallery = ({ navigateToIndex, onCanvasLoad, zoomIn, zoomOut }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useSquareRouting();

  useEffect(() => {
    if (canvasRef?.current && !initialized) {
      onCanvasLoad(canvasRef.current);
      initialized = true;
    }
  }, [canvasRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <StyledCanvas
        ref={canvasRef}
        id="gallery"
        width={window.innerWidth}
        height={window.innerHeight}
        // style={{ maxWidth: 1360, maxHeight: 800 }}
      />
      <Controls>
        <GalleryControls
          navigateToIndex={navigateToIndex}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
        <AccountMenu />
      </Controls>
      <InfoDialog />
    </Box>
  );
};

export default Gallery;
