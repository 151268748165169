export const DAPP_ADDRESS = process.env.REACT_APP_DAPP_ADDRESS;
export const HOLDINGS_DAPP_ADDRESS = process.env.REACT_APP_HOLDINGS_ADDRESS;

// Types
export const INTEGER = 'integer';
export const STRING = 'string';
export const WAVES = 'WAVES';

// Functions
export const ADD_OWNER_AND_AMOUNT = 'addOwnerAndAmount' as const;
export const AUTO_BUY_FUNCTION = 'autoBuy' as const;
export const BURN_FOR_EVOLVE_FUNCTION = 'burnForEvolve' as const;
export const BUY_FROM_HOLDINGS_FUNCTION = 'buyFromHoldings' as const;
export const EVOLVE_FUNCTION = 'evolve' as const;
export const PROMOTION_PURCHASE = 'adminPromotionPurchase' as const;
export const PURCHASE_FUNCTION = 'purchase' as const;
export const PUT_FOR_SALE_FUNCTION = 'putForSale' as const;
export const REVOKE_FOR_SALE_FUNCTION = 'revokeForSale' as const;
export const SET_HANDLE = 'adminSetHandle' as const;
