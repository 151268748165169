import { useEffect, useRef } from 'react';

type EventCallback<T> = (evt: T) => void;
function useEventListener<T>(
  eventName: string,
  handler: EventCallback<T>,
  element: HTMLCanvasElement | HTMLElement | Window | null = window,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<EventCallback<T>>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      function eventListener(event: T) {
        savedHandler.current ? savedHandler.current(event) : (() => {})();
      }

      if (element) {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        // Add event listener
        // @ts-ignore
        element.addEventListener(eventName, eventListener);
        // Remove event listener on cleanup
        return () => {
          // @ts-ignore
          element.removeEventListener(eventName, eventListener);
        };
      }
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}

export default useEventListener;
