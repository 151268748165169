export const getFroLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);

  if (!value) {
    return {};
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const hasOwner = (owner?: string) => Boolean(owner) && owner !== 'null';

type ResolveTitleFromURl = {
  pathname: string;
  title: string;
};

export const resolveTitleFromURl = ({
  pathname,
  title,
}: ResolveTitleFromURl) => {
  const parts = pathname.split('/').slice(1);

  switch (parts[0]) {
    case 'owner':
      return title.replace('{{owner}}', parts[1]);
    default:
      return title;
  }
};
