import { Children } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  ExpandedIndex,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccordionByName,
  setAccordionState,
} from '../../redux/slices/application';
import styled from '@emotion/styled';

const StyledAccordionPanel = styled(AccordionPanel)`
  > div {
    border-radius: 0 !important;
  }
`;

type Props = {
  headerSize?: string;
  title: string;
};

const AccordionSection: React.FC<Props> = ({
  children,
  headerSize = 'md',
  title,
}) => {
  const open = useSelector(getAccordionByName(title));
  const dispatch = useDispatch();
  const handleChange = (expandedIndex: ExpandedIndex) => {
    const index = Array.isArray(expandedIndex)
      ? expandedIndex
      : [expandedIndex];
    dispatch(
      setAccordionState({
        name: title,
        isOpen: index.length > 0,
      }),
    );
  };
  return (
    <Accordion
      allowMultiple
      defaultIndex={open ? [0] : []}
      width="100%"
      onChange={handleChange}
    >
      <AccordionItem
        width="100%"
        borderWidth="1px !important"
        borderColor="gray.800"
        borderRadius="base"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              boxShadow="none !important"
              outline="none"
              px="5"
              py="5"
              bg="gray.800"
              _expanded={{ bg: 'gray.800', color: 'white' }}
              _hover={{ bg: 'gray.800', color: 'white' }}
            >
              <Heading size={headerSize}>{title}</Heading>
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <StyledAccordionPanel p="0" borderRadius="0">
              {Children.map(children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, { visible: isExpanded });
                }
                return child;
              })}
            </StyledAccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionSection;
