import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { rowColToIndex } from './canvas/util';
import { closeDialog, openDialog } from '../redux/slices/dialog';

const useSquareRouting = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const [root, coords] = pathname.split('/').slice(1);
    if (root === 'square') {
      const [col, row] = coords.split(',').map(n => parseInt(n));
      const index = rowColToIndex(row, col);
      dispatch(openDialog(index));
    } else {
      dispatch(closeDialog());
    }
  }, [pathname]);
};

export default useSquareRouting;
