import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, NotificationState } from 'types';

const initialState: NotificationState = {
  waitForIdsInBlock: [],
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addWaitFor: (state: NotificationState, action: PayloadAction<string[]>) => {
      action.payload.forEach(coords => {
        state.waitForIdsInBlock.push(coords);
      });
    },
    removeWaitFor: (
      state: NotificationState,
      action: PayloadAction<string[]>,
    ) => {
      return {
        waitForIdsInBlock: state.waitForIdsInBlock.filter(
          id => !action.payload.includes(id),
        ),
      };
    },
  },
});

export const { addWaitFor, removeWaitFor } = notificationSlice.actions;

export const selectWaitForIdsInBlock = (state: AppState) =>
  state.notifications.waitForIdsInBlock;

export default notificationSlice.reducer;
