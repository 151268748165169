import React from 'react';
import { VStack } from '@chakra-ui/react';
import { RecentTransactions } from '../Transactions';

const LatestPurchasesPage = () => {
  return (
    <VStack>
      <RecentTransactions />
    </VStack>
  );
};

export default LatestPurchasesPage;
