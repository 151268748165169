import React from 'react';
import { Heading, HStack, VStack } from '@chakra-ui/react';
import { PurchaseOption } from '../../Purchase';
import useWaves from '../../../hooks/crypto/useWaves';

const ForSale = () => {
  const { batchPurchase } = useWaves();

  return (
    <VStack alignItems="flex-start" spacing="4">
      <Heading size="md">For sale</Heading>
      <HStack>
        <PurchaseOption onPurchase={batchPurchase} />
      </HStack>
    </VStack>
  );
};

export default ForSale;
