import { useEffect, useRef, useState } from 'react';
import { WEBSOCKET_ENDPOINT } from '../../constants';

export type WebSocketMessage = {
  action: string;
  data: any;
  rest: { [key: string]: any };
};

type Args = {
  onMessage?: (args: WebSocketMessage) => void;
};

const useWebSocket = ({ onMessage }: Args) => {
  const retriesRef = useRef<number>(0);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);

  const connectWs = () => {
    if (!websocket && retriesRef.current < 5) {
      const ws = new WebSocket(WEBSOCKET_ENDPOINT);

      ws.onclose = ({ wasClean, code, reason }) => {
        console.log('CLOSE');
        setTimeout(() => {
          console.log('HI');
          retriesRef.current += 1;
          connectWs();
        }, 1000);
      };

      ws.onerror = error => {
        console.log('Websocket error', error);
        ws.close();
      };

      ws.onmessage = ({ data }) => {
        const { action, data: resp, ...rest } = JSON.parse(data);
        onMessage?.({ action, data: resp, rest });
      };

      ws.onopen = () => {
        console.log('CONNECT');
        setWebsocket(ws);
      };
    }
  };

  useEffect(() => {
    connectWs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    websocket,
  };
};

export default useWebSocket;
