import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getUserAddress } from '../../redux/slices/application';
import axios from 'axios';
import { Box } from '@chakra-ui/react';
import Loader from '../Global/Loader';
import NftGrid from '../Nft/Grid';
import { wavesAssetToData } from '../../helpers/blockchain';
import { WAVES_ENDPOINT } from '../../constants';

const MySquaresPage = () => {
  const address = useSelector(getUserAddress);
  const { data, isLoading } = useQuery(address ?? '', () =>
    axios.get(`${WAVES_ENDPOINT}/assets/nft/${address}/limit/100`),
  );
  if (isLoading) {
    return (
      <Box width="100%" height="300px">
        <Loader message="Waiting for transactions" />
      </Box>
    );
  }

  const nftData = wavesAssetToData({ items: data?.data });

  return <NftGrid items={nftData} />;
};

export default MySquaresPage;
