import { useWavesPrice } from '../../services/blockchain';
import { Heading, HStack, Text } from '@chakra-ui/react';
import { WavesIcon } from '../Icons';
import React from 'react';
import { StackProps } from '@chakra-ui/layout/dist/declarations/src/stack';

type Props = {
  as?: React.FC<StackProps>;
  fontSize?: string;
  showConversion?: boolean;
  waves: number;
};

const TokenDisplay = ({
  as: AsComponent = HStack,
  fontSize = 'md',
  showConversion = true,
  waves,
}: Props) => {
  const { data: wavesPrice } = useWavesPrice();
  return (
    <AsComponent alignItems="center">
      <HStack spacing="2">
        <Heading size={fontSize}>{waves}</Heading>
        <WavesIcon size={16} />
      </HStack>

      {wavesPrice && showConversion && (
        <Text color="gray.500">(~${wavesPrice.toFixed(2)} USD)</Text>
      )}
    </AsComponent>
  );
};

export default TokenDisplay;
