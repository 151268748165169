import React from 'react';
import { useQuery } from 'react-query';
import { TRANSACTION_QUERY_KEY } from '../../constants';
import { getTransactions } from '../../services/api';
import {
  Box,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatDistance } from 'date-fns';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import FullContainerLoader from '../EmptyStates/FullContainerLoader';
import { EmptyMessage } from '../EmptyStates/';
import { getCurrentHeight } from '../../redux/slices/application';
import { Transaction } from 'types';
import { startCase } from 'lodash';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { normalizeWaves } from '../../helpers/blockchain';
import { WavesIcon } from '../Icons';
import {
  AUTO_BUY_FUNCTION,
  BUY_FROM_HOLDINGS_FUNCTION,
  EVOLVE_FUNCTION,
  PROMOTION_PURCHASE,
  PURCHASE_FUNCTION,
  PUT_FOR_SALE_FUNCTION,
  REVOKE_FOR_SALE_FUNCTION,
} from '../../hooks/crypto/constants';
import { TiShoppingCart, TiTag } from 'react-icons/ti';
import { GiGrowth } from 'react-icons/gi';
import { HiGift } from 'react-icons/hi';
import { BlockchainFunctions } from '../../types/waves';
import { MdOutlineAutoAwesome, MdRemoveShoppingCart } from 'react-icons/md';

const TableWrapper = styled(Box)`
  width: 100%;
  overflow: auto;
  position: relative;

  tr > th:first-of-type,
  tr > td:first-of-type {
    padding-left: 0;
  }

  thead {
    position: sticky;
    top: 0;
  }
`;

const TableWrapperInner = styled(Box)`
  width: 100%;
  min-width: 400px;
  max-height: 300px;
  overflow: auto;
`;

const actions: Partial<Record<BlockchainFunctions, string>> = {
  [AUTO_BUY_FUNCTION]: 'Auto Minted',
  [PROMOTION_PURCHASE]: 'Gifted',
  [BUY_FROM_HOLDINGS_FUNCTION]: PURCHASE_FUNCTION,
};

const actionIcons: Record<BlockchainFunctions, React.FC> = {
  [AUTO_BUY_FUNCTION]: MdOutlineAutoAwesome,
  [BUY_FROM_HOLDINGS_FUNCTION]: TiShoppingCart,
  [EVOLVE_FUNCTION]: GiGrowth,
  [PROMOTION_PURCHASE]: HiGift,
  [PURCHASE_FUNCTION]: TiShoppingCart,
  [PUT_FOR_SALE_FUNCTION]: TiTag,
  [REVOKE_FOR_SALE_FUNCTION]: MdRemoveShoppingCart,
};

type Props = {
  coords?: string;
};

const TransactionList = ({ coords }: Props) => {
  const { coords: activeCoords, status } = useSelector(getNftDataByActiveId);
  const height = useSelector(getCurrentHeight);
  const queryCoords = coords || activeCoords;
  const { data, isLoading } = useQuery<Transaction[]>(
    [TRANSACTION_QUERY_KEY, queryCoords, height, status],
    getTransactions(queryCoords),
  );

  if (!data?.length && !isLoading) {
    return <EmptyMessage message="No transactions" />;
  }

  return (
    <TableWrapper bg="gray.800" p="4">
      <FullContainerLoader loading={isLoading} message="Loading transactions" />
      <TableWrapperInner>
        <Table>
          <Thead bg="gray.800">
            <Tr>
              <Th>Action</Th>
              <Th>Amount</Th>
              <Th>Date</Th>
              <Th>Transaction</Th>
            </Tr>
          </Thead>
          <Tbody>
            {[...(data ?? [])]
              .reverse()
              .map(
                ({
                  amount,
                  functionName,
                  timestamp,
                  transactionId,
                  transactionUrl,
                }) => {
                  const amountInt = normalizeWaves(parseFloat(amount));
                  const Icon = actionIcons[functionName] ?? Box;
                  return (
                    <Tr key={transactionId}>
                      <Td width="15%">
                        <HStack>
                          <Icon />
                          <Text>
                            {startCase(actions?.[functionName] ?? functionName)}
                          </Text>
                        </HStack>
                      </Td>
                      <Td width="15%">
                        <HStack>
                          <Text>{amountInt || '-'}</Text>
                          {amountInt && <WavesIcon size={16} />}
                        </HStack>
                      </Td>
                      <Td width="30%">
                        {formatDistance(new Date(timestamp), new Date())} ago
                      </Td>
                      <Td width="40%">
                        <Link
                          href={transactionUrl}
                          isExternal
                          target="_blank"
                          rel="noopener noreferrer"
                          display="flex"
                          alignItems="center"
                        >
                          <Text isTruncated maxWidth="300px">
                            {transactionId}
                          </Text>
                          <HiOutlineExternalLink />
                        </Link>
                      </Td>
                    </Tr>
                  );
                },
              )}
          </Tbody>
        </Table>
      </TableWrapperInner>
    </TableWrapper>
  );
};

export default TransactionList;
