import React from 'react';
import { getStatusMessage, isSquareLoading } from '../../helpers/blockchain';
import Button from './Button';
import { ButtonProps } from '@chakra-ui/react';
import { SquareStatus } from '../../hooks/crypto/types';

type Props = ButtonProps & {
  loading?: boolean;
  status?: SquareStatus;
};

const LoadingButton: React.FC<Props> = ({
  children,
  disabled,
  loading = false,
  status,
  ...props
}) => {
  const isLoading = isSquareLoading(status) || loading;
  return (
    <Button
      disabled={disabled || loading || isSquareLoading(status)}
      isLoading={isLoading}
      loadingText={getStatusMessage(status, 'Initializing')}
      maxWidth="250px"
      {...props}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
