import React from 'react';
import { Box, Heading, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from 'redux/slices/nft';
import Card from '../../Global/Card';
import PurchasedActions from '../EcommActions/PurchasedActions';
import { useGetAssetData } from 'services/blockchain';
import { FullContainerLoader } from '../../EmptyStates';
import { Link } from 'react-router-dom';
import usePageNavigation from '../../../hooks/usePageNavigation';
import { OWNER_BASE } from '../../../constants/page-data';
import { NftTraits } from '../../Nft';

const PurchasedNFTContent = () => {
  const { owner } = useSelector(getNftDataByActiveId);
  const { data: blockchainData, isLoading } = useGetAssetData();
  const { getPath } = usePageNavigation();

  if (isLoading) {
    return (
      <Box width="100%" minHeight={['0', 300]} space="$4" position="relative">
        <FullContainerLoader loading message="Loading Data" />
      </Box>
    );
  }

  if (!blockchainData) {
    return null;
  }

  const ownerLink = getPath(`${OWNER_BASE}/${owner}`);

  return (
    <VStack alignItems="flex-start" spacing="4" width="100%">
      <Heading>
        Square{' '}
        {blockchainData.name
          .toLowerCase()
          .replace('gg', '')
          .replace('grid gang', '')
          .trim()}
      </Heading>
      <VStack space="$1">
        <Stack
          flexDirection={{ base: 'column', sm: 'row' }}
          width="100%"
          gap="3"
          alignItems="flex-start"
        >
          <Text as={Box} color="gray.500">
            Owned by:
          </Text>
          <VStack spacing="2" mt="0 !important">
            <Text flex="1" isTruncated>
              <Link to={ownerLink}>{owner}</Link>
            </Text>
            {blockchainData?.handle && (
              <HStack width="100%">
                <Text>@{blockchainData.handle}</Text>
              </HStack>
            )}
          </VStack>
        </Stack>
        <HStack width="100%">
          <Text color="gray.500">Created:</Text>
          <Box isTruncated flex="1">
            {format(
              new Date(blockchainData?.issueTimestamp ?? null),
              'MMMM do yyyy',
            )}
          </Box>
        </HStack>
      </VStack>
      <Card>
        <VStack
          width="100%"
          alignItems="flex-start"
          borderRadius="md"
          spacing="4"
        >
          <PurchasedActions assetId={blockchainData.assetId} />
        </VStack>
      </Card>
      <NftTraits />
    </VStack>
  );
};

export default PurchasedNFTContent;
