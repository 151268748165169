import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState,
  MAX_TRANSACTIONS,
  NftData,
  NftDict,
  NftState,
} from '../../types';
import uniqBy from 'lodash/uniqBy';
import { selectDialog } from './dialog';

const initialState: NftState = {
  data: {},
  dict: {},
  transactions: [],
};

const createNftData = (payload: NftData[], startValue: NftDict = {}) =>
  payload.reduce<NftDict>((acc, { index, ...data }) => {
    if (typeof index !== 'undefined' && index !== null) {
      acc[index] = data;
    }
    return acc;
  }, startValue);

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setNftData: (state: NftState, action: PayloadAction<NftData[]>) => {
      const newNftData = createNftData(action.payload);
      return {
        ...state,
        data: newNftData,
        dict: action.payload.reduce<NftDict>((acc, nft) => {
          if (nft?.coords) {
            acc[nft.coords] = nft;
          }
          return acc;
        }, {}),
      };
    },
    addNftData: (state: NftState, action: PayloadAction<NftData[]>) => {
      action.payload.forEach(data => {
        if (data?.index) {
          state.data[data.index] = data;
          if (data?.coords) {
            state.dict[data.coords] = data;
          }
        }
      });
    },
    addTransactions: (state: NftState, action: PayloadAction<NftData[]>) => {
      state.transactions = uniqBy(
        action.payload.concat(state.transactions),
        'id',
      ).slice(0, MAX_TRANSACTIONS);
    },
  },
});

export const { addNftData, setNftData, addTransactions } = nftSlice.actions;

export const getNftData = (state: AppState) => state?.nft?.data ?? [];
export const getNftByIndex = (index?: number | null) => (state: AppState) =>
  state?.nft?.data?.[index ?? -1] ?? {};
export const getNftDictData = (state: AppState) => state?.nft?.dict ?? {};
export const getTransactions = (state: AppState) =>
  state?.nft?.transactions ?? [];

export const getNftDataByActiveId = createSelector(
  selectDialog,
  getNftData,
  getNftDictData,
  ({ activeId }, data, dict) => {
    const nft = data?.[activeId ?? ''] ?? ({} as NftData);

    if (nft?.parent && nft.parent !== 'null') {
      return dict?.[nft.parent] ?? ({} as NftData);
    }

    return nft ?? ({} as NftData);
  },
);

export default nftSlice.reducer;
