import { createContext } from 'react';
import { Vector2D } from '../../hooks/canvas/util';

export type SelectionBounds = Vector2D[];

export type DialogContextData = {
  selectedSize?: number;
  selectedCoord?: Vector2D;
  selectionBounds?: SelectionBounds;
  setSelectedCoord?: (arg?: Vector2D) => void;
  setSelectionBounds?: (arg?: SelectionBounds) => void;
  setSelectedSize?: (arg?: number) => void;
};

export const DialogContext = createContext<DialogContextData>({});
