import { Transaction } from '../types';
import {
  AUTO_BUY_FUNCTION,
  BUY_FROM_HOLDINGS_FUNCTION,
  PURCHASE_FUNCTION,
} from '../hooks/crypto/constants';
import { BlockchainFunctions } from '../types/waves';
import { normalizeWaves } from './blockchain';
import { subDays } from 'date-fns';

export type GraphTransaction = {
  amount: number;
  date: Date;
};

const purchaseFunctions: BlockchainFunctions[] = [
  AUTO_BUY_FUNCTION,
  BUY_FROM_HOLDINGS_FUNCTION,
  PURCHASE_FUNCTION,
];

export const formatTransactionGraphData = (
  txs?: Transaction[],
): GraphTransaction[] => {
  if (!txs) {
    return [];
  }
  const data = txs.reduce<GraphTransaction[]>((acc, tx) => {
    if (!purchaseFunctions.includes(tx.functionName)) {
      return acc;
    }

    const gtx: GraphTransaction = {
      amount: normalizeWaves(parseFloat(tx.amount)),
      date: new Date(tx.timestamp),
    };

    acc.push(gtx);

    return acc;
  }, []);

  if (data.length === 1) {
    const firstData = new Date(data[0].date);
    const firstEntry: GraphTransaction = {
      amount: 0,
      date: subDays(firstData, 1),
    };
    return [firstEntry, ...data];
  }

  return data;
};
