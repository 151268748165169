import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import useWaves from 'hooks/crypto/useWaves';
import Card from '../../Global/Card';
import { getNftDataByActiveId } from '../../../redux/slices/nft';
import { PurchaseOption } from '../../Purchase';

const ColorSquareNftContent = () => {
  const { batchPurchase } = useWaves();

  const { x: col, y: row } = useSelector(getNftDataByActiveId);

  return (
    <>
      <Heading>
        Claim {col},{row}
      </Heading>
      <VStack space="$1">
        <Text>
          Get in early and purchase this square to generate your very own Grid
          Grid Nft
        </Text>
      </VStack>
      <Card>
        <Box
          p="4"
          width="100%"
          alignItems="flex-start"
          borderRadius="md"
          spacing="4"
          display="flex"
          flexDirection="column"
          gap="5"
        >
          <PurchaseOption onPurchase={batchPurchase} />
        </Box>
      </Card>
    </>
  );
};

export default ColorSquareNftContent;
