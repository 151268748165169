import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { rowColToIndex, Vector2D } from './canvas/util';
import { SQUARE } from '../constants';
import { useContext } from 'react';
import { CanvasContext } from './canvas';

const usePageNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navigateToIndex } = useContext(CanvasContext);
  const [searchParams] = useSearchParams();

  const getPath = (path: string) => `${path}${location.search}`;
  const navigateTo = (path: string) => {
    navigate(getPath(path));
  };

  const navigateToSquare = (coords: Vector2D, shouldCenter = true) => {
    let center: number | string | null = searchParams.get('center');
    const scale = searchParams.get('scale');

    if (shouldCenter) {
      center = rowColToIndex(coords.y, coords.x);
      navigateToIndex({
        index: center,
        openInfoDialog: false,
      });
    }
    navigate(
      `${SQUARE}/${coords.x},${coords.y}?center=${center}&scale=${scale}`,
    );
  };

  return {
    getPath,
    navigateTo,
    navigateToSquare,
  };
};

export default usePageNavigation;
