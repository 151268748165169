import { Box, useColorModeValue } from '@chakra-ui/react';

type cardProps = {
  children: React.ReactNode;
};

const Card = ({ children }: cardProps) => {
  const backgroundColor = useColorModeValue('gray.50', 'gray.800');
  return (
    <Box
      p="3"
      backgroundColor={backgroundColor}
      borderRadius="md"
      width="100%"
      height="100%"
    >
      {children}
    </Box>
  );
};

export default Card;
