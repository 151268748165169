import { ModalOverlay } from '@chakra-ui/react';

const CustomOverlay = () => (
  <ModalOverlay
    bg="blackAlpha.500"
    backdropFilter="blur(8px) hue-rotate(90deg)"
  />
);

export default CustomOverlay;
