import React from 'react';
import { NftData } from 'types';
import { NftCard } from './index';
import { Grid } from '@chakra-ui/react';

type Props = {
  items: NftData[];
};

const NftGrid = ({ items }: Props) => (
  <Grid
    templateColumns={{
      base: 'repeat(2, minmax(100px, 1fr))',
      md: 'repeat(3, minmax(100px, 1fr))',
      lg: 'repeat(5, minmax(100px, 1fr))',
    }}
    gap={6}
    width="100%"
  >
    {items.map(item => (
      <NftCard key={item.id} data={item} />
    ))}
  </Grid>
);

export default NftGrid;
