import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react';
import { useAppSelector } from '../../hooks/redux';
import { selectDialog } from '../../redux/slices/dialog';
import { BURNED, EVOLVE, HOME, OUTSIDE } from '../../constants';
import styled from '@emotion/styled';
import { Vector2D, vector2D } from '../../hooks/canvas/util';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import InfoDialogContent from './Content/InfoDialogContent';
import { TransactionGraph, TransactionList } from '../Transactions';
import usePageNavigation from '../../hooks/usePageNavigation';
import NftImage from '../Nft/Image';
import Loader from '../Global/Loader';
import HTMLGrid from '../Gallery/HTMLGrid';
import { DialogContext } from './DialogContext';
import AccordionSection from './AccordionSection';
import CustomOverlay from './CustomOverlay';
import { useIsFetching } from 'react-query';
import { getAppLoading } from '../../redux/slices/application';
import { EvolveButton } from '../Nft';

const DELAY = 400;

const ModalWrapper = styled(Modal)`
  max-width: 900px;
`;

const InfoDialog = () => {
  const [selectedSize, setSelectedSize] = useState<number | undefined>(1);
  const [selectedCoord, setSelectedCoord] = useState<Vector2D | undefined>(
    vector2D(0, 0),
  );
  const [selectionBounds, setSelectionBounds] = useState<
    Vector2D[] | undefined
  >([vector2D(0, 0), vector2D(0, 0)]);
  const { navigateTo } = usePageNavigation();
  const { activeId, isOpen } = useAppSelector(selectDialog);
  const [isLoading, setIsLoading] = useState(true);
  const [transitioning, setTransitioning] = useState(true);
  const { id, image, owner, saleAmount, status, x, y } =
    useSelector(getNftDataByActiveId);
  const isFetching = useIsFetching();
  const isAppLoading = useSelector(getAppLoading);

  useEffect(() => {
    const selected = vector2D(x ?? 0, y ?? 0);
    setSelectedCoord(selected);
    if (owner && !saleAmount) {
      setSelectionBounds([selected, selected]);
    }
  }, [x, y]);

  useEffect(() => {
    setIsLoading(true);
    setTransitioning(true);
  }, [activeId]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (activeId && isFetching <= 2 && !isAppLoading) {
      timeout = setTimeout(() => {
        setIsLoading(false);
        setTransitioning(false);
      }, DELAY);
    }
    return () => clearTimeout(timeout);
  }, [activeId, isFetching, isAppLoading]);

  useEffect(() => {
    if (activeId) {
      const timeout = setTimeout(() => {
        setTransitioning(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [activeId]);

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(true);
      setTransitioning(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    navigateTo(HOME);
  };

  const contextValue = {
    selectedCoord,
    selectedSize,
    selectionBounds,
    setSelectedCoord,
    setSelectionBounds,
    setSelectedSize,
  };

  const displayLoader = isLoading || isAppLoading;

  return (
    <DialogContext.Provider value={contextValue}>
      <ModalWrapper
        onClose={handleClose}
        isOpen={isOpen}
        scrollBehavior={OUTSIDE}
      >
        <CustomOverlay />
        <ModalContent
          maxW={{
            lg: '1200px',
            base: '600px',
          }}
          m="3"
          p="5"
        >
          <ModalCloseButton top="5" right="4" size="lg" zIndex="99" />
          <ModalBody p="0">
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              pointerEvents={displayLoader ? 'auto' : 'none'}
              opacity={displayLoader ? 1 : 0}
              transition="ease-out"
              zIndex="98"
              backgroundColor="#2D3748E6"
            >
              <Box position="absolute" height="50%" width="100%">
                <Loader message="Loading" />
              </Box>
            </Box>
            {!transitioning && id && (
              <>
                <VStack width="100%" spacing="6">
                  {status === EVOLVE ||
                  status === BURNED ||
                  image === 'null' ? (
                    <Box height="400px" width="100%" position="relative">
                      <Loader message="Evolving" />
                    </Box>
                  ) : (
                    <>
                      <Flex
                        alignItems="flex-start"
                        flexDirection={{
                          lg: 'row',
                          base: 'column',
                        }}
                        gridGap="7"
                        width="100%"
                      >
                        <VStack widht="100%">
                          <Box
                            width={{
                              base: '100%',
                              md: '600px',
                            }}
                            minWidth={{
                              base: '100%',
                              md: '600px',
                            }}
                          >
                            <NftImage id={id} image={image} />
                          </Box>
                          <EvolveButton />
                        </VStack>
                        <VStack
                          flex="1"
                          alignItems="flex-start"
                          spacing="5"
                          width="100%"
                        >
                          <InfoDialogContent />
                        </VStack>
                      </Flex>
                    </>
                  )}
                  <AccordionSection title="Transactions">
                    <TransactionList />
                    <Heading size="sm" pl="4" bg="gray.800" pt="5">
                      Over time
                    </Heading>
                    <TransactionGraph />
                  </AccordionSection>
                  <AccordionSection title="Surrounding Squares">
                    <HTMLGrid
                      coords={selectedCoord ?? vector2D(x ?? 0, y ?? 0)}
                      highlightBounds={selectionBounds}
                      highlightedCoord=" "
                      itemSize={50}
                    />
                  </AccordionSection>
                </VStack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </ModalWrapper>
    </DialogContext.Provider>
  );
};

export default InfoDialog;
