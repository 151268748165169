import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../../redux/slices/nft';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { normalizeWaves } from '../../../helpers/blockchain';
import { WavesIcon } from '../../Icons';
import useWaves from '../../../hooks/crypto/useWaves';
import LoadingButton from '../../Global/LoadingButton';

const OwnerUpForSale = () => {
  const { revokeForSale } = useWaves();
  const { assetId, coords, saleAmount, status } =
    useSelector(getNftDataByActiveId);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    if (assetId && coords) {
      await revokeForSale({ assetId, coords });
    }
    setLoading(false);
  };

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text>
          You have this up for sale for: {normalizeWaves(saleAmount)}{' '}
        </Text>
        <WavesIcon size={16} />
      </HStack>
      <LoadingButton loading={loading} status={status} onClick={handleClick}>
        Revoke sale
      </LoadingButton>
    </VStack>
  );
};

export default OwnerUpForSale;
