type FaqData = {
  question: string;
  answer: string | string[];
};

export const faqData: FaqData[] = [
  {
    question: "Why was the Waves Blockchain chosen for the Grid Gang platform?",
    answer:
      "The Waves blockchain was chosen for this project because of its proven track record of security, functionality, affordability and speed of transactions. Waves has proven over the years to be one of the premier projects in the Cryptocurrency space and we wanted our users, regardless of crypto/ NFT experience, the ability to participate in Grid Gang.",
  },
  // {
  //   question: "How do I get a Waves Keeper Wallet to participate in Grid Gang?",
  //   answer: "Please follow the tutorial included on our Grid Gang website (if you require extra help please visit our Discord where we will be more than happy to help with any questions or concerns you may have)",
  // },
  {
    question: "How do I get Waves to participate in Grid Gang?",
    answer:
      'There are many ways to acquire Waves coins in order to participate. Waves coins are listed on <a href="https://www.coingecko.com/en/coins/waves#markets" target="_blank">most major exchanges</a>. You can also convert over 750+ Cryptocurrencies into Waves coins by simply going to https://swapspace.co/?to=waves&amount=1',
  },
  {
    question: "Why can't I use MetaMask instead of Waves Keeper?",
    answer:
      'You will be able to soon after Waves V6 is released to the Main Net ( For more information <a href="https://docs.waves.tech/en/ride/v6/#metamask-support" target="_blank">visit the docs</a>)',
  },
  {
    question:
      "Can I store my Grid Gang NFT assets off the Grid Gang site in my Waves Keeper Wallet? ",
    answer:
      "The NFT image itself will remain on the Grid Gang site but the token ownership reflected on the Waves blockchain will be stored in your Waves Keeper wallet with the web address to your asset.",
  },
  {
    question:
      "Why are there so many squares ( 1,048,576 ) on the Grid Gang grid?",
    answer: [
      "The reason there are this many squares on the Grid Gang grid is we wanted to make the entry as affordable and as enjoyable as possible for people. To merge their NFTs into larger more prominent assets on the Grid Gang Grid.",
      "The other reason is the Grid is deflationary because once you have a block of 4 Generation 1 NFTs, they will then merge into a larger single image Generation 2 NFT image. This will cut the supply down by 75% . This would leave 262,000 Generation 2 NFTs on the Grid Gang Grid . If all users acquire 4 Blocks of Generation 2 NFTs (which equals 16 spaces), that would cut down the supply by another 75% which leaves only 65,500 Generation 3 NFTs on the Grid Gang grid. To get to a Generation 4 NFT a user will need to acquire 4 connecting Blocks of 16 NFTs which equals 64 spaces this would drop supply another 75% to 16,375 Generation 4 NFTs on the grid. To get to Generation 5 a user will need 4 blocks of 64 spaces which equals 256 blocks this could drop supply by another 75% down to a mere 4,093 NFTs on the entire Grid Gang Grid. To get a Generation 6 NFT a user will need to acquire 4 Blocks of 256 spaces which would equal 1024 spaces; this could cut supply down by another 75% to a mere 1,023 NFTs on the Grid Gang Grid. A Generation 7 NFT will require a user to acquire 4 blocks of 1024 spaces which would equal 4 096 spaces this will cut the supply by another 75% to 255 NFTs on the Grid Gang grid. By the end the entire Grid could technically be owned by one wallet as one massive NFT which covers the entire Grid.",
    ],
  },
  {
    question: "Why should I buy NFTs on the Grid Gang platform ?",
    answer: [
      "The NFT world is constantly evolving around us and Grid Gang offers users a unique opportunity to own digital assets in a deflationary environment . With a burn mechanism built into the platform with the merging of different generations , Each and every NFT asset generation becomes more scarce with every single merge that takes place on the Grid. With a marketplace built into the Grid Gang website a user can buy and sell assets with the greatest of ease and extremely low Waves gas fees + 2.5% transaction fee compared to other marketplaces.",
      "Another unique feature is the ability to put your social media profile link on the backside of the NFT asset. For example when someone clicks on the NFT image showing on the Grid Gang Grid they will then be taken to the information side of the digital asset . This will show the Minting date as well as any and all other sales of the NFT. It is in this section the owner of the NFT can enter their Social media links in order for people to contact them regarding negotiation of a possible sale or merely to see if someone just wants to show off who owns this Amazing digital art piece.This platform can also be used to advertise your band as well especially with larger pieces as people will click on the NFT to see who is King of the Grid and owns the largest pieces!",
      "These amazing digital works of art have all been created by one of the most original and cutting edge artists to come out of Toronto Canada in a long time. That alone is a reason to get on the Grid early and often. It is definitely in a users best interest to get in as early and often as possible to generate higher Generations on the Grid.",
    ],
  },
  {
    question: "What will happen to the Website if the Core Team leaves?",
    answer:
      "All assets will be uploaded to a blockchain file storage, domains will be repointed and will live there forever.",
  },
];
