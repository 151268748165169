import * as React from 'react';
import { IconSize } from '../../types/components';

export const WavesIcon = ({ size = 24 }: IconSize) => (
  <svg width={size} height={size} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <circle cx={16} cy={16} fill="#0155ff" r={16} />
      <path d="m16 6 10 10-10 10L6 16z" fill="#fff" />
    </g>
  </svg>
);
