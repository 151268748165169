import React from 'react';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../../redux/slices/nft';
import PurchasedNFTContent from './Purchased';
import ColorSquareNftContent from './ColorSquareNftContent';

const InfoDialogContent = () => {
  const { owner } = useSelector(getNftDataByActiveId);

  if (owner && owner !== 'null') {
    return <PurchasedNFTContent />;
  }

  return <ColorSquareNftContent />;
};

export default InfoDialogContent;
