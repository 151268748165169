import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Wrapper = styled(HStack)<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
  justify-content: center;
`;

type Props = {
  height?: string;
  message: string;
};

const EmptyMessage = ({ height = '100px', message }: Props) => {
  return (
    <Wrapper height={height} bg="gray.800" borderRadius="md">
      <Text>{message}</Text>
    </Wrapper>
  );
};

export default EmptyMessage;
