import React, { useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  VStack,
  Text,
} from '@chakra-ui/react';
import { BiRocket } from 'react-icons/bi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsSquare, BsSquareFill } from 'react-icons/bs';
import { HiGift } from 'react-icons/hi';
import { BiPurchaseTagAlt, BiInfoSquare } from 'react-icons/bi';
import usePageNavigation from '../../hooks/usePageNavigation';
import {
  ABOUT_US,
  ADMIN_GIFT_SQUARE,
  AVAILABLE_SQUARES,
  FAQ,
  GETTING_STARTED,
  LATEST_PURCHASED_SQUARES,
  OWNER_BASE,
} from '../../constants/page-data';
import { useSelector } from 'react-redux';
import { getUserAddress } from '../../redux/slices/application';
import { HOLDINGS_DAPP_ADDRESS } from '../../hooks/crypto/constants';
import SocialLinks from '../Global/SocialLinks';
import styled from '@emotion/styled';
import { getRandomColorScheme } from '../../helpers/squares';

const StyledLink = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none !important;
  gap: 1rem;
  padding: 1rem 1.25rem 1.25rem;
  line-height: 1;
  background: transparent;
  border: none;

  &:hover {
    background: #4a5568;
  }

  span {
    display: block;
    flex: 1;
  }
`;

const AccountMenu = () => {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { navigateTo } = usePageNavigation();
  const userAddress = useSelector(getUserAddress);
  const isAdmin = userAddress === HOLDINGS_DAPP_ADDRESS;
  const color = useMemo(() => getRandomColorScheme(), []);

  const handleLinkClick = (link: string) => {
    setIsOpen(false);
    navigateTo(link);
  };

  return (
    <>
      <Button
        mt="0px !important"
        boxShadow="dark-lg"
        aria-label="Open menu"
        ref={btnRef}
        colorScheme={color}
        rightIcon={<GiHamburgerMenu />}
        onClick={() => setIsOpen(true)}
      >
        Menu
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setIsOpen(false)}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody px={0}>
            <VStack spacing={0} alignItems="flex-start" pt="4">
              {userAddress && (
                <>
                  <Heading size="xs" textAlign="left" pt="3" pb="2" pl="5">
                    Your stuff
                  </Heading>
                  <StyledLink
                    onClick={() =>
                      handleLinkClick(`${OWNER_BASE}/${userAddress}`)
                    }
                  >
                    <BsSquareFill />
                    <Text>My claimed squares</Text>
                  </StyledLink>
                </>
              )}

              {isAdmin && (
                <>
                  <Box py="4" width="100%">
                    <Divider />
                  </Box>
                  <Heading size="xs" textAlign="left" pt="3" pb="2" pl="5">
                    Admin
                  </Heading>
                  <StyledLink
                    onClick={() => handleLinkClick(ADMIN_GIFT_SQUARE)}
                  >
                    <HiGift />
                    <Text>Gift square</Text>
                  </StyledLink>
                </>
              )}
              <Box py="4" width="100%">
                <Divider />
              </Box>
              <Heading size="xs" textAlign="left" pt="3" pb="2" pl="5">
                Activity
              </Heading>
              <StyledLink
                onClick={() => handleLinkClick(LATEST_PURCHASED_SQUARES)}
              >
                <BiPurchaseTagAlt />
                <Text>Latest Purchases</Text>
              </StyledLink>
              <StyledLink onClick={() => handleLinkClick(AVAILABLE_SQUARES)}>
                <BsSquare />
                <Text>Available squares</Text>
              </StyledLink>
              <Box py="4" width="100%">
                <Divider />
              </Box>
              <Heading size="xs" textAlign="left" pt="3" pb="2" pl="5">
                Info
              </Heading>
              <StyledLink onClick={() => handleLinkClick(ABOUT_US)}>
                <BiInfoSquare />
                <Text>About us</Text>
              </StyledLink>
              <StyledLink onClick={() => handleLinkClick(GETTING_STARTED)}>
                <BiRocket />
                <Text>Getting started</Text>
              </StyledLink>
              <StyledLink onClick={() => handleLinkClick(FAQ)}>
                <BiRocket />
                <Text>FAQ</Text>
              </StyledLink>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-start">
            <SocialLinks />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AccountMenu;
