import { IMAGE_WIDTH } from './constants';
import {
  Bounds,
  indexToRowCol,
  rowColToIndex,
  vector2D,
  Vector2D,
} from './util';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clampBounds } from '../../helpers/squares';

// export const scaleLevels = [
//   0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2,
// ];
export const scaleLevels = [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3];
export const defaultScaleLevel = 5;
type ARGS = {
  canvas: HTMLCanvasElement | null;
  ctx: CanvasRenderingContext2D | null;
  render: () => void;
};

export const useCanvasViewport = ({ canvas, ctx, render }: ARGS) => {
  const [searchParams] = useSearchParams();
  const [scaleIndex, setStateScaleIndex] = useState<number>(
    parseInt(
      searchParams.get('scale')?.toString() ?? defaultScaleLevel.toString(),
    ),
  );

  useEffect(() => {
    if (ctx) {
      render();
    }
  }, [scaleIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getScale = (index: number = scaleIndex) => scaleLevels[index];
  const imageWidth = (scale: number = getScale()) => IMAGE_WIDTH * scale;
  const imageHeight = (scale: number = getScale()) => IMAGE_WIDTH * scale;
  const totalRowsOnScreen = () =>
    Math.ceil((canvas?.height ?? 1) / imageHeight());
  const totalColsOnScreen = () =>
    Math.ceil((canvas?.width ?? 1) / imageWidth());

  const zoomIn = (): number => {
    return setScaleIndex(scaleIndex + 1);
  };
  const zoomOut = (): number => {
    return setScaleIndex(scaleIndex - 1);
  };

  const setZoomLevel = (zoom: number): number => {
    return setScaleIndex(zoom);
  };

  const resolveScaleIndex = (scale: number) =>
    Math.min(scaleLevels.length - 1, Math.max(0, scale));

  const setScaleIndex = (scale: number): number => {
    const newIndex = resolveScaleIndex(scale);
    setStateScaleIndex(newIndex);
    return newIndex;
  };

  // @TODO memoize this will likely be a good pref boost
  const getIndexesInViewport = (offset: Vector2D): number[] => {
    const startCol = Math.floor(Math.abs(offset.x / imageWidth()));
    const startRow = Math.floor(Math.abs(offset.y / imageHeight()));

    const indexes = [];
    for (
      let row = startRow - 2;
      row < startRow + totalRowsOnScreen() + 2;
      row++
    ) {
      for (
        let col = startCol - 2;
        col < startCol + totalColsOnScreen() + 2;
        col++
      ) {
        const index = rowColToIndex(row, col);
        if (index >= 0) {
          indexes.push(rowColToIndex(row, col));
        }
      }
    }
    return indexes;
  };

  const getScreenCoordsBounds = (offset: Vector2D): Bounds => {
    const tlCol = Math.floor(Math.abs(offset.x / imageWidth()));
    const tlRow = Math.floor(Math.abs(offset.y / imageHeight()));

    const blCol = Math.ceil(tlCol + totalColsOnScreen());
    const blRow = Math.ceil(tlRow + totalRowsOnScreen());

    return {
      tl: vector2D(tlCol, tlRow),
      br: vector2D(blCol, blRow),
    };
  };

  const clampOrigin = (
    pos: Vector2D,
    width: number = imageWidth(),
    height: number = imageHeight(),
  ) =>
    clampBounds({
      pos,
      height,
      totalHeight: canvas?.height ?? 0,
      totalWidth: canvas?.width ?? 0,
      width,
    });

  const mousePositionToImageIndex = ({
    height = imageHeight(),
    mousePosition,
    origin,
    width = imageWidth(),
  }: {
    height?: number;
    mousePosition: Vector2D;
    origin: Vector2D;
    width?: number;
  }) => {
    const topLeftColumnIndex = Math.abs(Math.floor(origin.x / width));
    const topLeftRowIndex = Math.abs(Math.floor(origin.y / height));
    const offset = vector2D(
      (origin.x % width) + (canvas?.getBoundingClientRect()?.x ?? 0),
      (origin.y % height) + (canvas?.getBoundingClientRect()?.y ?? 0),
    );
    const mouseIndexX = Math.floor((mousePosition.x - offset.x) / width);
    const mouseIndexY = Math.floor((mousePosition.y - offset.y) / height);

    const row = topLeftRowIndex + mouseIndexY;
    const col = topLeftColumnIndex + mouseIndexX;

    return rowColToIndex(
      origin.y === 0 || origin.y % height === 1 ? row : row - 1,
      origin.x === 0 || origin.x % width === 1 ? col : col - 1,
    );
  };

  const imageIndexToOrigin = (
    index: number,
    offset?: Vector2D,
    width: number = imageWidth(),
    height: number = imageHeight(),
  ): Vector2D => {
    const { row, col } = indexToRowCol(index);
    return vector2D(
      -(col * width) + (offset?.x ?? 0),
      -(row * height) + (offset?.y ?? 0),
    );
  };

  return {
    clampOrigin,
    getScale,
    getIndexesInViewport,
    getScreenCoordsBounds,
    imageHeight,
    imageIndexToOrigin,
    imageWidth,
    indexToRowCol,
    mousePositionToImageIndex,
    resolveScaleIndex,
    scaleIndex,
    scaleLevels,
    setScaleIndex,
    setZoomLevel,
    zoomIn,
    zoomOut,
  };
};
