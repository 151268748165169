import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import { getCurrentHeight } from '../../redux/slices/application';
import { useQuery } from 'react-query';
import { Transaction } from '../../types';
import { TRANSACTION_QUERY_KEY } from '../../constants';
import { getTransactions } from '../../services/api';
import {
  formatTransactionGraphData,
  GraphTransaction,
} from '../../helpers/data';
import { AxisOptions, Chart } from 'react-charts';
import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import { EmptyMessage } from '../EmptyStates';

const StyledChartWrapper = styled(Box)`
  width: 100%;
  height: 100%;

  .domainAndTicks .tick {
    > line {
      stroke: #fff;
    }
    text {
      fill: #fff !important;
    }
  }

  .domainAndTicks {
    > line {
      stroke: #fff;
    }
  }

  .grid .tick:first-child line {
    //stroke: #fff;
  }
`;

const Graph = () => {
  const { coords: activeCoords, status } = useSelector(getNftDataByActiveId);
  const height = useSelector(getCurrentHeight);
  const queryCoords = activeCoords;
  const { data } = useQuery<Transaction[]>(
    [TRANSACTION_QUERY_KEY, queryCoords, height, status],
    getTransactions(queryCoords),
  );

  const transactionsGraphData = formatTransactionGraphData(data);

  if (!data || !transactionsGraphData.length) {
    return <EmptyMessage message="No transactions" />;
  }

  const primaryAxis: AxisOptions<GraphTransaction> = {
    elementType: 'area',
    getValue: datum => format(datum.date, 'MMM d YYY HH:mm'),
  };
  const secondaryAxes: AxisOptions<GraphTransaction>[] = [
    {
      elementType: 'area',
      getValue: datum => datum.amount,
    },
  ];

  const chartData = [
    {
      label: 'Waves amount',
      data: transactionsGraphData,
    },
  ];

  return (
    <Box height="200px" bg="gray.800" p="4">
      <StyledChartWrapper width="100%" height="100%">
        <Chart
          options={{
            data: chartData,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </StyledChartWrapper>
    </Box>
  );
};

export default Graph;
