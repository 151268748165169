import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';

const gridAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const GridLoader = styled(Box)`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    animation: ${gridAnimation} 1.2s linear infinite;
  }
  div:nth-of-type(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  div:nth-of-type(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  div:nth-of-type(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  div:nth-of-type(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  div:nth-of-type(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  div:nth-of-type(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  div:nth-of-type(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  div:nth-of-type(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  div:nth-of-type(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
`;

type Props = {
  message?: string;
};

const Loader = ({ message }: Props) => {
  return (
    <HStack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <VStack width="250px">
        <GridLoader>
          <Box backgroundColor="pink.700" />
          <Box backgroundColor="green.700" />
          <Box backgroundColor="blue.700" />
          <Box backgroundColor="red.500" />
          <Box backgroundColor="cyan.700" />
          <Box backgroundColor="yellow.400" />
          <Box backgroundColor="teal.700" />
          <Box backgroundColor="purple.700" />
          <Box backgroundColor="orange.400" />
        </GridLoader>
        {message && <Text>{message}</Text>}
      </VStack>
    </HStack>
  );
};

export default Loader;
