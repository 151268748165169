import React from 'react';
import { useSelector } from 'react-redux';
import { getUserAddress } from '../../redux/slices/application';
import ownedIcon from 'images/owned-icon.png';
import saleIcon from 'images/sale-icon.png';
import { Box, Image } from '@chakra-ui/react';

type Props = {
  as?: React.FC;
  owner?: string;
  saleAmount?: number;
  size?: string | number;
};

const NftStatusIcon = ({
  as: WrapperComponent = Box,
  owner,
  saleAmount,
  size = '40px',
}: Props) => {
  const userAddress = useSelector(getUserAddress);

  if (userAddress === owner) {
    return (
      <WrapperComponent>
        <Image boxSize={size} src={ownedIcon} />
      </WrapperComponent>
    );
  }

  if (saleAmount) {
    return (
      <WrapperComponent>
        <Image boxSize={size} src={saleIcon} />
      </WrapperComponent>
    );
  }

  return null;
};

export default NftStatusIcon;
