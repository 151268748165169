import React, { useState } from 'react';
import { Button as StyledButton, ButtonProps } from '@chakra-ui/react';
import { getRandomColorScheme } from '../../helpers/squares';

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [randomScheme] = useState(getRandomColorScheme());
  return (
    <StyledButton colorScheme={randomScheme} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
