import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNftDictData } from '../../redux/slices/nft';
import { CoordsMatrix } from '../../types/ui';
import { clampGridNumber, getCoordsMatrix } from '../../helpers/squares';
import { Box, Button } from '@chakra-ui/react';
import NftImage from '../Nft/Image';
import { vector2D, Vector2D } from '../../hooks/canvas/util';
import usePageNavigation from '../../hooks/usePageNavigation';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { NftData } from '../../types';
import { activeStates } from '../../constants';

export const TOP_LEFT = 'top-left' as const;
export const CENTER = 'center' as const;

const MotionBox = motion(Box);
export type HTMLGridOrient = typeof CENTER | typeof TOP_LEFT;

type Props = {
  coords: Vector2D;
  highlightBounds?: Vector2D[];
  highlightedCoord?: string;
  itemSize?: number;
  orient?: HTMLGridOrient;
  xSize?: number;
  ySize?: number;
  visible?: boolean;
};

const StyledButton = styled(Button)<{ isSelected: boolean }>`
  border-radius: 0 !important;
  outline: none !important;

  z-index: ${({ isSelected }) => (isSelected ? 2 : 1)};
  ${({ isSelected }) =>
    isSelected &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: solid 3px #e53e3e;
    }
  `}
`;

const backgroundColor = [
  'rgba(197,48,48, 0.35)',
  'rgba(192,86,33, 0.35)',
  'rgba(236,201,75,0.35)',
  'rgba(47,133,90,0.35)',
  'rgba(56,178,172,0.35)',
  'rgba(49,130,206,0.35)',
  'rgba(0,181,216,0.35)',
  'rgba(128,90,213,0.35)',
  'rgba(184,50,128,0.35)',
];

const borderColor = [
  '#c53030',
  '#c05621',
  '#ecc94b',
  '#2f855a',
  '#38B2AC',
  '#3182CE',
  '#00B5D8',
  '#805AD5',
  '#B83280',
];

type HighlightBoundsProps = {
  bounds: Vector2D[];
  data?: NftData;
  itemSize: number;
  offset?: string;
  topLeftCoord?: string;
};

const HighlightBounds = ({
  bounds,
  data,
  itemSize,
  offset = '0,0',
  topLeftCoord,
}: HighlightBoundsProps) => {
  const [{ x: tx, y: ty }, { x: bx, y: by }] = bounds;
  const [offsetX, offsetY] = offset.split(',').map(str => parseInt(str));

  const topXWithOffset = Math.abs(tx - offsetX);
  const topYWithOffset = Math.abs(ty - offsetY);
  const bottomXWithOffset = Math.abs(bx - offsetX);
  const bottomYWithOffset = Math.abs(by - offsetY);
  const genModifier = Math.pow(2, parseInt(data?.gen?.toString() ?? '1') - 1);

  return (
    <MotionBox
      animate={{
        backgroundColor,
        borderColor,
      }}
      transition={{
        duration: borderColor.length,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'loop',
      }}
      position="absolute"
      top={`${topYWithOffset * itemSize}px`}
      left={`${topXWithOffset * itemSize}px`}
      width={`${
        (bottomXWithOffset - topXWithOffset + 1) * itemSize * genModifier
      }px`}
      height={`${
        (bottomYWithOffset - topYWithOffset + 1) * itemSize * genModifier
      }px`}
      border="solid 5px #333"
      backgroundColor="rgba(0,0,0,0.5)"
      pointerEvents="none"
      zIndex={9}
    />
  );
};

const HTMLGrid = ({
  coords: userCoords,
  highlightBounds,
  highlightedCoord,
  itemSize = 50,
  orient = CENTER,
  xSize: usersXSize,
  ySize: usersYSize,
  visible = true,
}: Props) => {
  const placedRef = useRef<Set<string>>();
  const { navigateToSquare } = usePageNavigation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [coords, setCoords] = useState<CoordsMatrix>([]);
  const nftDict = useSelector(getNftDictData);
  const selectedCoords = `${userCoords.x},${userCoords.y}`;
  const selectedSquare = nftDict[selectedCoords];

  useEffect(() => {
    setTimeout(() => {
      if (containerRef?.current) {
        const { x, y } = userCoords;
        const xSize =
          usersXSize ??
          Math.ceil(
            containerRef?.current?.getBoundingClientRect().width / itemSize,
          );
        const ySize =
          usersYSize ??
          Math.ceil(
            containerRef?.current?.getBoundingClientRect().height / itemSize,
          );

        const matrixParams = {
          xSize,
          ySize,
          xStart:
            orient === CENTER ? clampGridNumber(x - Math.floor(xSize / 2)) : x,
          yStart:
            orient === CENTER ? clampGridNumber(y - Math.floor(ySize / 2)) : y,
        };
        const matrix = getCoordsMatrix(matrixParams);
        setCoords(matrix);
      }
    }, 25);
  }, [itemSize, orient, userCoords, usersXSize, usersYSize, visible]);

  const renderSquare = useCallback(
    (coord?: string) => {
      if (!coord) {
        return null;
      }

      const data = nftDict?.[coord];

      if (!data) {
        return <NftImage id={coord} />;
      }

      const { gen, ...d } = data;
      return <NftImage gen={parseInt(gen?.toString() ?? '1')} {...d} />;
    },
    [nftDict, userCoords, visible],
  );

  placedRef.current = new Set<string>();
  return (
    <Box
      width="100%"
      height={270}
      ref={containerRef}
      overflow="hidden"
      position="relative"
    >
      {coords.map((coords, rowI) =>
        coords.map((coord, colI) => {
          let data = nftDict?.[coord];
          const isSelected = (highlightedCoord || selectedCoords) === coord;
          let top = rowI * itemSize;
          let left = colI * itemSize;

          if (data && data.parent && data?.parent !== 'null') {
            const currentX = data?.x ?? 0;
            const currentY = data?.y ?? 0;
            data = nftDict?.[data.parent] ?? {};

            if (!data || placedRef?.current?.has(data?.coords ?? '')) {
              return null;
            }
            // @ts-ignore
            const xDiff = currentX - data?.x ?? 0;
            // @ts-ignore
            const yDiff = currentY - data?.y ?? 0;

            top = top - yDiff * itemSize;
            left = left - xDiff * itemSize;
          }

          const gen: number = parseInt(data?.gen ?? '1');
          const genModifier = Math.pow(2, gen - 1);
          const opacity =
            data?.status && !activeStates.includes(data?.status) ? 0.35 : 1;

          placedRef?.current?.add(data?.coords ?? '');

          return (
            <StyledButton
              key={coord}
              position="absolute"
              top={`${top}px`}
              left={`${left}px`}
              display="block"
              height={`${itemSize * genModifier}px`}
              isSelected={isSelected}
              onClick={() =>
                data?.x && data?.y && navigateToSquare(vector2D(data.x, data.y))
              }
              width={`${itemSize * genModifier}px`}
              minWidth={`${itemSize * genModifier}px`}
              variant="unstyled"
              outline="none"
              opacity={opacity}
            >
              {renderSquare(data?.coords)}
            </StyledButton>
          );
        }),
      )}
      {highlightBounds && coords?.[0]?.[0] && (
        <HighlightBounds
          bounds={highlightBounds}
          data={selectedSquare}
          itemSize={itemSize}
          offset={coords[0][0]}
          topLeftCoord={coords[0][0]}
        />
      )}
    </Box>
  );
};

export default HTMLGrid;
