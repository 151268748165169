import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { NftData } from '../../types';
import { SQUARE_BY_OWNER } from '../../constants';
import { getOwnerSquares } from '../../services/api';
import { Box } from '@chakra-ui/react';
import Loader from '../Global/Loader';
import NftGrid from '../Nft/Grid';
import React from 'react';

const OwnerSquares = () => {
  const location = useLocation();
  const ownerId = location.pathname.split('/').slice(-1)[0];

  const { data, isLoading } = useQuery<NftData[]>(
    [SQUARE_BY_OWNER, ownerId],
    () => getOwnerSquares(ownerId),
  );

  if (isLoading) {
    return (
      <Box width="100%" height="300px">
        <Loader message="Waiting for transactions" />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box width="100%" height="300px">
        <Loader message="Nothing purchased" />
      </Box>
    );
  }

  return <NftGrid items={data} />;
};

export default OwnerSquares;
