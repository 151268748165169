import axios from 'axios';
import {
  API_ENDPOINT,
  AVAILABLE_SQUARES_ENDPOINT,
  BUSY,
  CAN_EVOLVE_ENDPOINT,
  LATEST_PURCHASES_ENDPOINT,
  RANDOM_SQUARE_ENDPOINT,
  SQUARE_BY_OWNER,
  SQUARE_META_ENDPOINT,
  STATUS_ENDPOINT,
  TRANSACTIONS_ENDPOINT,
  UPDATE_FROM_BLOCKCHAIN_ENDPOINT,
} from '../constants';
import { SquareStatus } from '../hooks/crypto/types';
import { NftData } from '../types';

export const getAvailableSquares = () => {
  return axios
    .get<NftData[]>(`${API_ENDPOINT}${AVAILABLE_SQUARES_ENDPOINT}`)
    .then(({ data }) => data);
};

export const getLatestPurchases = () => {
  return axios
    .get<NftData[]>(`${API_ENDPOINT}${LATEST_PURCHASES_ENDPOINT}`)
    .then(({ data }) => data);
};

export const getOwnerSquares = (ownerId: string) => {
  return axios
    .get<NftData[]>(`${API_ENDPOINT}${SQUARE_BY_OWNER}?owner=${ownerId}`)
    .then(({ data }) => data);
};

export const getTransactions = (coords?: string) => () => {
  if (!coords) {
    return [];
  }
  return axios
    .get(`${API_ENDPOINT}${TRANSACTIONS_ENDPOINT}?coords=${coords}`)
    .then(({ data }) => data);
};

export const getCanEvolve = (coords?: string) => () => {
  if (!coords) {
    return Promise.resolve([]);
  }
  return axios
    .get(`${API_ENDPOINT}${CAN_EVOLVE_ENDPOINT}?coord=${coords}`)
    .then(({ data }) => data);
};

export const getRandomSquare = () =>
  axios
    .get<NftData>(`${API_ENDPOINT}${RANDOM_SQUARE_ENDPOINT}`)
    .then(({ data }) => data);

export const getSquareMeta = (coords?: string, gen: string = '1') => {
  if (!coords) {
    return Promise.resolve([]);
  }
  return axios
    .get(`${API_ENDPOINT}${SQUARE_META_ENDPOINT}?coord=${coords}&gen=${gen}`)
    .then(({ data }) => data);
};

export const updateFromBlockchain = (timeoutDuration: number = 2000) => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      await axios.get(`${API_ENDPOINT}${UPDATE_FROM_BLOCKCHAIN_ENDPOINT}`);
      resolve(true);
    }, timeoutDuration);
  });
};

type SetSquareStatusArgs = {
  coords: string | string[];
  status: SquareStatus;
};

export const setSquareStatus = ({
  coords,
  status = BUSY,
}: SetSquareStatusArgs) =>
  axios.post(`${API_ENDPOINT}${STATUS_ENDPOINT}`, {
    coords,
    status,
  });
