import React, { useState } from 'react';
import { AVAILABLE_SQUARES_ENDPOINT } from '../../constants';
import { useQuery } from 'react-query';
import { getAvailableSquares } from '../../services/api';
import NftGrid from '../Nft/Grid';
import { Box, VStack } from '@chakra-ui/react';
import Loader from '../Global/Loader';
import { NftData } from '../../types';

const AvailableSquaresPage = () => {
  const [lastFetched] = useState<number>(new Date().getTime());

  const { data, isLoading } = useQuery<NftData[]>(
    [AVAILABLE_SQUARES_ENDPOINT, lastFetched],
    () => getAvailableSquares(),
  );

  if (!data || isLoading) {
    return (
      <Box width="100%" height="300px">
        <Loader message="Searching for squares" />
      </Box>
    );
  }

  return (
    <VStack>
      <NftGrid items={data} />
    </VStack>
  );
};

export default AvailableSquaresPage;
