import React, { useEffect, useRef } from 'react';
import {
  Box,
  HStack,
  Spinner,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectWaitForIdsInBlock } from '../../redux/slices/notifications';

const getToastDescription = (ids: string[]) =>
  `Waiting for the Waves block to complete`;

const ToastContent = ({ message }: { message: string }) => {
  return (
    <HStack
      alignItems="center"
      p="4"
      backgroundColor="gray.700"
      borderRadius="base"
      borderLeft="4px solid"
      borderColor="cyan.400"
    >
      <Spinner />
      <Box>
        <Text fontWeight="bold">{message}</Text>
      </Box>
    </HStack>
  );
};

const WaitNotification = () => {
  const toast = useToast();
  const waitingFor = useSelector(selectWaitForIdsInBlock);
  const toastIdRef = useRef<ToastId>();

  useEffect(() => {
    if (waitingFor.length > 0 && !toastIdRef?.current) {
      toastIdRef.current = toast({
        duration: null,
        isClosable: false,
        position: 'top-right',
        render(): React.ReactNode {
          return <ToastContent message={getToastDescription(waitingFor)} />;
        },
        status: 'info',
      });
    } else {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
        toastIdRef.current = undefined;
      }
    }
  }, [waitingFor]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />;
};

export default WaitNotification;
