import React from 'react';
import {
  Box,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { pageData } from 'constants/page-data';
import usePageNavigation from '../../hooks/usePageNavigation';
import { resolveTitleFromURl } from '../../helpers/application';
import CustomOverlay from '../Dialogs/CustomOverlay';

const PageDialogWrapper = () => {
  const { navigateTo } = usePageNavigation();
  const location = useLocation();

  const handleClose = () => {
    navigateTo('/');
  };

  const page =
    Object.keys(pageData).find(exp =>
      new RegExp(exp, 'ig').test(location.pathname),
    ) ?? 'not-found';

  const { title, contentComponent: Content } = pageData?.[page] ?? {};

  const resolvedTitle = resolveTitleFromURl({
    pathname: location.pathname,
    title,
  });

  return (
    <Modal
      onClose={handleClose}
      size="wide"
      scrollBehavior="outside"
      isOpen={!!Content}
      motionPreset="slideInBottom"
    >
      <CustomOverlay />
      <ModalContent m="3">
        {title && (
          <ModalHeader
            fontSize="3xl"
            borderBottom="solid 1px"
            borderBottomColor="gray.600"
          >
            {resolvedTitle}
          </ModalHeader>
        )}
        <ModalCloseButton top="5" right="4" size="lg" />
        <Box p="5">{Content && <Content />}</Box>
      </ModalContent>
    </Modal>
  );
};

export default PageDialogWrapper;
