import { COLUMN_NUM, ROW_NUM } from './constants';
import { NftDict } from 'types';

export type Vector2D = {
  x: number;
  y: number;
};

export type Bounds = {
  tl: Vector2D;
  br: Vector2D;
};

export const getImagesByIndexes = ({
  data,
  indexes,
}: {
  data: NftDict;
  indexes: number[];
}) =>
  indexes.reduce<NftDict>((acc, index) => {
    if (data[index]) {
      acc[index] = data[index];
    }
    return acc;
  }, {});

export const vector2D = (x: number, y: number): Vector2D => ({
  x,
  y,
});

export const indexToRowCol = (index: number): { row: number; col: number } => ({
  row: Math.floor(index / ROW_NUM),
  col: index % COLUMN_NUM || 0,
});

export const rowColToIndex = (row: number, col: number) =>
  row * COLUMN_NUM + col;
export const origin = vector2D(0, 0);

export const hashToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

export const getBoundingCoords = ({
  imageHeight,
  imageWidth,
  index,
}: {
  imageHeight: number;
  imageWidth: number;
  index: number;
}) => {
  const canvas = document.getElementById('gallery');
  const { col, row } = indexToRowCol(index);
  const numX = Math.ceil(
    (canvas?.getBoundingClientRect()?.width ?? window.innerWidth) / imageWidth,
  );
  const numY = Math.ceil(
    (canvas?.getBoundingClientRect()?.height ?? window.innerHeight) /
      imageHeight,
  );

  const xDistance = Math.ceil(numX * 1.75);
  const yDistance = Math.ceil(numY * 1.75);

  const startX = Math.max(Math.floor(col - xDistance), 0);
  const endX = Math.ceil(col + xDistance);
  const startY = Math.max(row - yDistance, 0);
  const endY = Math.ceil(row + yDistance);

  const coords = [];

  for (let x = startX; x <= endX; x++) {
    for (let y = startY; y <= endY; y++) {
      coords.push(`${x},${y}`);
    }
  }

  return coords;
};
