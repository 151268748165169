import '@fontsource/ubuntu/400.css';
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Ubuntu',
    body: 'Ubuntu',
  },
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          maxWidth: ['95%', '95%', '1400px'],
          width: '95%',
          margin: '0 16px',
          minHeight: '75vh',
        },
      }),
    },
  },
});

export default theme;
