import React, { useContext, useEffect, useRef, useState } from 'react';
import { getBoundingCoords, vector2D } from '../../hooks/canvas/util';
import {
  COLUMN_NUM,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  ROW_NUM,
} from '../../hooks/canvas/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNftData,
  addTransactions,
  getNftDictData,
  setNftData,
} from '../../redux/slices/nft';
import { useSearchParams } from 'react-router-dom';
import {
  defaultScaleLevel,
  scaleLevels,
} from '../../hooks/canvas/useCanvasViewport';
import Loader from '../Global/Loader';
import { CHAIN_UPDATE, EVOLVE, GET_DATA, STATUS_UPDATE } from '../../constants';
import useWebSocket, { WebSocketMessage } from './useWebSocket';
import {
  getAppLoading,
  setAppLoading,
  setHeight,
} from '../../redux/slices/application';
import { CanvasContext } from '../../hooks/canvas';
import { NftData } from 'types';
import { Box } from '@chakra-ui/react';
import { getRandomSquare } from '../../services/api';

const SocketProvider: React.FC = ({ children }) => {
  const { addForceRenderSquare, cleanImageCache, navigateToIndex } =
    useContext(CanvasContext);
  const cacheRef = useRef<NftData[]>();
  const chunkedDataRef = useRef<NftData[]>([]);
  const [prevFetch, setPrevFetch] = useState<{ center: number; scale: number }>(
    { center: 0, scale: 0 },
  );
  const dispatch = useDispatch();
  const loading = useSelector(getAppLoading);
  const [searchParams] = useSearchParams();
  const dictData = useSelector(getNftDictData);

  const handleMessage = async ({ action, data, rest }: WebSocketMessage) => {
    const { height, totalChunks } = rest;
    switch (action) {
      case GET_DATA:
        chunkedDataRef.current = [...(chunkedDataRef?.current ?? []), data];
        if (chunkedDataRef.current.length === totalChunks) {
          dispatch(
            setNftData(
              (cacheRef?.current ?? [])
                .concat([...chunkedDataRef.current])
                .flat(),
            ),
          );
          chunkedDataRef.current = [];
          dispatch(setAppLoading(false));
        }
        break;
      case CHAIN_UPDATE:
      case STATUS_UPDATE:
        // await preloadImages(data);
        dispatch(addTransactions(data));
        dispatch(addNftData(data));
        // const removeCoords = data.map(
        //   ({ coords }: { coords: string }) => coords,
        // );
        // dispatch(removeWaitFor(removeCoords));
        break;
      case EVOLVE:
        const nftData = data as NftData[];
        addForceRenderSquare(
          nftData.map(({ coords, gen }) => `${coords},${gen}`),
        );
        dispatch(addNftData(nftData));
        // await preloadImages(nftData);
        cleanImageCache({
          coords: nftData.map(({ x, y }) => vector2D(x ?? 0, y ?? 0)),
          maxGen: nftData.reduce<number>(
            (maxGen, { gen }) => Math.max(maxGen, parseInt(gen ?? '0')),
            0,
          ),
        });
        break;
    }

    if (height) {
      dispatch(setHeight(height));
    }
  };

  const { websocket } = useWebSocket({
    onMessage: handleMessage,
  });

  const fetchData = () => {
    const { center: prevCenter, scale: prevScale } = prevFetch;
    const centerParam = searchParams.get('center');

    if (centerParam && websocket) {
      const scale =
        scaleLevels[
          parseInt(searchParams.get('scale') ?? defaultScaleLevel.toString())
        ];
      const center = parseInt(centerParam, 10);

      if (center === prevCenter && scale === prevScale) {
        return;
      }

      const { coords: data, cachedValues } = getBoundingCoords({
        index: center,
        imageHeight: IMAGE_HEIGHT * scale,
        imageWidth: IMAGE_WIDTH * scale,
      }).reduce<{ coords: string[]; cachedValues: NftData[] }>(
        (acc, coord) => {
          if (dictData[coord]) {
            acc.cachedValues.push(dictData[coord]);
          } else {
            acc.coords.push(coord);
          }
          return acc;
        },
        { coords: [], cachedValues: [] },
      );

      if (data.length > cachedValues.length) {
        dispatch(setAppLoading(true));
      }

      websocket?.send(
        JSON.stringify({
          action: 'getData',
          data,
        }),
      );

      cacheRef.current = cachedValues;
      setPrevFetch({
        center,
        scale,
      });
    }
  };

  useEffect(() => {
    if (websocket) {
      if (!searchParams.get('center')) {
        (async () => {
          const random = await getRandomSquare();
          const randomCenter =
            random?.index ?? Math.floor(Math.random() * (COLUMN_NUM * ROW_NUM));
          navigateToIndex({ index: randomCenter });
        })();

        // navigate(`${location.pathname}?center=${randomCenter}&scale=4`, {
        //   replace: true,
        // });
      } else {
        fetchData();
      }
    }
  }, [websocket]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [searchParams.get('center'), searchParams.get('scale')]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        pointerEvents={loading ? 'auto' : 'none'}
        opacity={loading ? 1 : 0}
        transition="ease-out"
        zIndex="98"
        backgroundColor="#2D3748E6"
      >
        <Loader message="Loading the gang" />
      </Box>
      {children}
    </>
  );
};

export default SocketProvider;
