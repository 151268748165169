import { FaDiscord } from 'react-icons/fa';
import { RiTwitterFill, RiInstagramFill, RiMailLine } from 'react-icons/ri';
import { HStack, Link } from '@chakra-ui/react';

const SocialLinks = () => {
  return (
    <HStack alignItems="center" spacing="3">
      <Link
        colorScheme="teal"
        variant="link"
        href="https://discord.com/invite/cKP6Wze6nG"
        target="_blank"
      >
        <FaDiscord size="26px" />
      </Link>
      <Link
        colorScheme="teal"
        variant="link"
        href="https://twitter.com/nftgridgang"
        target="_blank"
      >
        <RiTwitterFill size="26px" />
      </Link>
      <Link
        colorScheme="teal"
        variant="link"
        href="https://www.instagram.com/nftgridgang/"
        target="_blank"
      >
        <RiInstagramFill size="26px" />
      </Link>
      <Link colorScheme="teal" href="mailto:info@gridgang.io" variant="link">
        <RiMailLine size="26px" />
      </Link>
    </HStack>
  );
};

export default SocialLinks;
