import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';

type Props = {
  alignItems?: string;
  buttonLabel?: string;
  height?: string;
  justifyContent?: string;
  message: string;
  onClick?: () => void;
};

const MessageWithButton = ({
  alignItems = 'center',
  buttonLabel,
  height = '75px',
  justifyContent = 'center',
  message,
  onClick,
}: Props) => {
  return (
    <VStack
      alignItems={alignItems}
      width="100%"
      justifyContent={justifyContent}
      height={height}
      bg="gray.800"
      borderRadius="md"
    >
      <Text>{message}</Text>
      {buttonLabel && (
        <Button onClick={() => onClick?.()} size="md">
          {buttonLabel}
        </Button>
      )}
    </VStack>
  );
};

export default MessageWithButton;
