import React from 'react';
import AboutUs from 'components/Pages/AboutUs';
import Faq from '../components/Pages/Faq';
import MySquares from '../components/Pages/MySquares';
import LatestPurchasesPage from '../components/Pages/LatestPurchaes';
import AvailableSquaresPage from '../components/Pages/AvailableSquares';
import GettingStartedPage from '../components/Pages/GettingStarted';
import OwnerSquares from '../components/Pages/OwnerSquares';
import AdminGiftSquares from '../components/Pages/admin/GiftSquares';

type PageDataItem = {
  contentComponent: React.FC;
  title: string;
};

type PageData = { [url: string]: PageDataItem };

export const ABOUT_US = '/about-us';
export const FAQ = '/faq';
export const MY_SQUARES = '/my-squares';
export const LATEST_PURCHASED_SQUARES = '/latest-squares';
export const AVAILABLE_SQUARES = '/available-squares';
export const GETTING_STARTED = '/getting-started';
export const OWNER_BASE = '/owner';
export const OWNER_SQUARES = `${OWNER_BASE}/.*`;

export const ADMIN_BASE = '/admin';
export const ADMIN_GIFT_SQUARE = `${ADMIN_BASE}/gift`;

export const pageData: PageData = {
  [ABOUT_US]: {
    title: 'About us',
    contentComponent: AboutUs,
  },
  [AVAILABLE_SQUARES]: {
    title: 'Available squares',
    contentComponent: AvailableSquaresPage,
  },
  [FAQ]: {
    title: 'FAQ',
    contentComponent: Faq,
  },
  [GETTING_STARTED]: {
    title: 'Getting Started',
    contentComponent: GettingStartedPage,
  },
  [LATEST_PURCHASED_SQUARES]: {
    title: 'Latest Purchases',
    contentComponent: LatestPurchasesPage,
  },
  [MY_SQUARES]: {
    title: 'My Squares',
    contentComponent: MySquares,
  },
  [OWNER_SQUARES]: {
    title: '{{owner}} Squares',
    contentComponent: OwnerSquares,
  },
  [ADMIN_GIFT_SQUARE]: {
    title: 'Gift Squares',
    contentComponent: AdminGiftSquares,
  },
};
