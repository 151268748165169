import React from 'react';
import { Box } from '@chakra-ui/react';
import Loader from '../Global/Loader';
import NftGrid from '../Nft/Grid';
import { useQuery } from 'react-query';
import { NftData } from '../../types';
import { LATEST_PURCHASES_ENDPOINT } from '../../constants';
import { getLatestPurchases } from '../../services/api';

const RecentTransactions = () => {
  const { data, isLoading } = useQuery<NftData[]>(
    [LATEST_PURCHASES_ENDPOINT],
    () => getLatestPurchases(),
    {
      refetchInterval: 10000,
    },
  );

  if (!data?.length || isLoading) {
    return (
      <Box width="100%" height="300px">
        <Loader message="Loading transactions" />
      </Box>
    );
  }

  return <NftGrid items={data} />;
};

export default RecentTransactions;
