import React from 'react';
import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Wrapper = styled(VStack)<{ loading?: boolean; maxHeight: string }>`
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  align-items: center;
  justify-content: center;
  opacity: ${({ loading }) => (loading ? 1 : 0)};
  padding: 0;
  pointer-events: ${({ loading }) => (loading ? 'auto' : 'none')};
`;

type Props = {
  loading?: boolean;
  maxHeight?: string;
  message?: string;
  size?: string;
};

const FullContainerLoader = ({
  loading,
  maxHeight = '100%',
  message,
  size = 'md',
}: Props) => {
  return (
    <Wrapper loading={loading} maxHeight={maxHeight} space="0">
      <VStack position="relative" zIndex="2" padding="0">
        <Spinner size={size} />
        {message && <Text>{message}</Text>}
      </VStack>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        opacity="0.5"
        zIndex="2"
        style={{ margin: 0 }}
      />
    </Wrapper>
  );
};

export default FullContainerLoader;
