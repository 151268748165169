import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { FiChevronRight, FiSearch } from 'react-icons/fi';
import styled from '@emotion/styled';
import { NavigateToIndexArgs } from '../../hooks/canvas';
import { rowColToIndex } from '../../hooks/canvas/util';
import { getRandomColorScheme } from '../../helpers/squares';

const Controls = styled(VStack)`
  bottom: 16px;
  right: 16px;
  z-index: 99;
  display: flex;
  flex-direction: column;
`;

type Props = {
  navigateToIndex: (args: NavigateToIndexArgs) => void;
  zoomIn: () => void;
  zoomOut: () => void;
};

const GalleryControls = ({ navigateToIndex, zoomIn, zoomOut }: Props) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const color = useMemo(() => getRandomColorScheme(), []);

  useEffect(() => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  }, [origin]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNavigateToIndex = () => {
    let index = parseInt(searchValue, 10);
    if (searchValue.indexOf(',') !== -1) {
      const [x, y] = searchValue
        .split(',')
        .map(num => parseInt(num.trim(), 10));
      index = rowColToIndex(y, x);
    }

    navigateToIndex({ index });
    setSearchOpen(false);
    setSearchValue('');
  };

  return (
    <Controls>
      <Popover
        closeOnBlur
        closeOnEsc
        initialFocusRef={searchInputRef}
        onOpen={() => setSearchOpen(true)}
        onClose={() => setSearchOpen(true)}
      >
        <PopoverTrigger>
          <Box backgroundColor="gray.700" borderRadius="base">
            <IconButton
              boxShadow="dark-lg"
              aria-label="Navigate to index"
              colorScheme={color}
              icon={<FiSearch />}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <PopoverArrow />
          <InputGroup>
            <Input
              ref={searchInputRef}
              onChange={evt => setSearchValue(evt.target.value)}
              onKeyDown={evt => evt.key === 'Enter' && handleNavigateToIndex()}
              placeholder="Enter id your are looking for"
              value={searchValue}
            />
            <InputRightAddon aria-label="Navigate to index">
              <FiChevronRight onClick={handleNavigateToIndex} />
            </InputRightAddon>
          </InputGroup>
        </PopoverContent>
      </Popover>
      {/*<VStack spacing={0} backgroundColor="gray.700" borderRadius="base">*/}
      {/*  <Box>*/}
      {/*    <IconButton*/}
      {/*      aria-label="Zoom in"*/}
      {/*      icon={<FiPlus />}*/}
      {/*      onClick={() => zoomIn()}*/}
      {/*      style={{ borderRadius: '0' }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*  <Box backgroundColor="gray.700" borderRadius="md">*/}
      {/*    <IconButton*/}
      {/*      aria-label="Zoom out"*/}
      {/*      icon={<FiMinus />}*/}
      {/*      onClick={() => zoomOut()}*/}
      {/*      style={{ borderRadius: '0' }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</VStack>*/}
    </Controls>
  );
};

export default GalleryControls;
