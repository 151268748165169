import axios from 'axios';
import { DAPP_ADDRESS } from '../hooks/crypto/constants';
import { BlockchainData, GroupedBlockchainData } from '../hooks/crypto/types';
import { rowColToIndex } from '../hooks/canvas/util';
import { IMAGE_HEIGHT, IMAGE_WIDTH, ROW_NUM } from '../hooks/canvas/constants';
import { NftData, RatePairsResponse, WavesData } from 'types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../redux/slices/nft';
import { WAVES_ENDPOINT } from '../constants';

export const getInitialData = async (data: NftData[]) => {
  const resp = await axios.get<BlockchainData[]>(
    `${WAVES_ENDPOINT}/addresses/data/${DAPP_ADDRESS}`,
  );

  if (resp.data) {
    const grouped = resp.data.reduce<GroupedBlockchainData>(
      (acc, { key, value }: BlockchainData) => {
        if (/[a-zA-Z0-9]*._(asset|name|owner|xy)/gi.test(key)) {
          const [assetId, objKey] = key.split('_');
          return {
            ...acc,
            [assetId]: {
              ...(acc?.[assetId] ?? {}),
              xy: assetId
                .split(',')
                .slice(0, 2)
                .map(num => parseInt(num)) as [number, number],
              [objKey]: value,
            },
          };
        }
        return acc;
      },
      {},
    );

    return Object.entries(grouped).reduce<NftData[]>(
      (acc, [assetId, { asset, xy, owner }]) => {
        if (!xy) {
          return acc;
        }
        const [col, row] = xy;
        const index = rowColToIndex(row, col);
        const rowIndex = Math.floor(index / ROW_NUM);
        acc[index] = {
          ...(acc?.[index] ?? {}),
          id: assetId,
          image: asset?.indexOf('/A=.png') === -1 ? asset : '',
          owner,
          x: (index % ROW_NUM) * IMAGE_WIDTH,
          y: rowIndex * IMAGE_HEIGHT,
        };
        return acc;
      },
      [...data],
    );
  }

  return data;
};

export const useWavesPrice = () =>
  useQuery(
    ['wavesPrice'],
    () =>
      axios
        .post<RatePairsResponse>('https://waves.exchange/api/v1/rates', {
          pairs: ['WAVES/DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p'],
        })
        .then(resp => resp.data?.data?.[0]?.data?.rate ?? 0),
    {
      refetchInterval: 5000,
    },
  );

export const useGetAssetData = () => {
  const { coords, image } = useSelector(getNftDataByActiveId);
  const txId =
    image && image !== 'null'
      ? new URL(image ?? '')?.pathname?.split('.')?.[0]?.slice(1)
      : '';

  return useQuery(['nft', txId], async () => {
    const dataPrefix = coords;
    if (!dataPrefix) {
      return [];
    }
    const dataResp = await axios
      .get<WavesData[]>(
        `${WAVES_ENDPOINT}/addresses/data/${DAPP_ADDRESS}?matches=${dataPrefix.replace(
          /,/g,
          '%2C',
        )}%5Ba-zA-z_%5D%2A`,
      )
      .then(resp => resp.data);

    if (dataResp?.[0]) {
      const assetId = dataResp.find(({ key }) => key.indexOf('_assetId') > -1);
      const ownerId = dataResp.find(({ key }) => key.indexOf('_owner') > -1);

      if (assetId?.value) {
        const resp = await axios
          .get(`${WAVES_ENDPOINT}/assets/details/${assetId.value}`)
          .then(resp => resp.data);

        const handle = await getUserHandle(ownerId?.value);
        return {
          ...resp,
          handle,
        };
      }
    }
    // }

    return {};
  });
};

export const getUserHandle = async (address?: string) => {
  if (!address) {
    return '';
  }

  return axios
    .get<WavesData[]>(
      `${WAVES_ENDPOINT}/addresses/data/${DAPP_ADDRESS}?matches=${address}_handle`,
    )
    .then(resp => resp.data?.[0]?.value ?? '');
};
