import { faqData } from '../../constants/faq';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  VStack,
} from '@chakra-ui/react';

const FAQ = () => {
  return (
    <Accordion allowMultiple>
      {faqData.map(({ question, answer }) => {
        const content = Array.isArray(answer) ? answer : [answer];

        return (
          <AccordionItem key={question}>
            <h2>
              <AccordionButton py="5">
                <Box flex="1" textAlign="left" fontWeight="bold">
                  {question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VStack alignItems="flex-start">
                {content.map((text, i) => (
                  <Text key={i} dangerouslySetInnerHTML={{ __html: text }} />
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default FAQ;
