import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  activeStates,
  CAN_EVOLVE_ENDPOINT,
  evolvePlaceholders,
} from '../../constants';
import { getCanEvolve } from '../../services/api';
import { CanEvolveResponse } from '../../types';
import useWaves from '../../hooks/crypto/useWaves';
import NftImage from './Image';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { GiGrowth } from 'react-icons/gi';
import { BsArrowRight } from 'react-icons/bs';
import { hashToColor } from '../../hooks/canvas/util';
import { getDisplayImage } from '../../helpers/squares';
import { getUserAddress } from '../../redux/slices/application';

const EVOLVE_SQUARE_WIDTH = 300;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(-45deg, #ecc94b, #dd6b20, #e53e3e);
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;

  &:hover {
    background: #e53e3e;
  }
`;

const EvolveButton = () => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const userAddress = useSelector(getUserAddress);
  const { coords, owner, status } = useSelector(getNftDataByActiveId);
  const { evolveSquare } = useWaves();

  const { data } = useQuery<CanEvolveResponse>(
    [CAN_EVOLVE_ENDPOINT, coords, owner],
    getCanEvolve(coords),
    {
      enabled: userAddress === owner,
    },
  );

  const handleEvolveClick = async () => {
    setIsOpen(false);
    if (data?.evolveCoords) {
      await Promise.all(
        data.evolveCoords.map(
          ({ assetId, coords }) =>
            assetId && coords && evolveSquare({ assetId, coords }),
        ),
      );
    }
  };

  if (
    !data?.canEvolve ||
    !data?.evolveCoords ||
    !data?.maxGen ||
    (status && !activeStates.includes(status))
  ) {
    return null;
  }

  const { maxGen } = data;
  const squareWidth = maxGen === 1 ? 1 : Math.pow(2, maxGen - 1);
  const imageWidth = EVOLVE_SQUARE_WIDTH / squareWidth;
  const evolveBackgroundColor = hashToColor(
    data.evolveCoords?.[0]?.id ?? 'lam lam',
  );

  return (
    <>
      <StyledButton
        leftIcon={<GiGrowth />}
        size="lg"
        width="100%"
        onClick={() => setIsOpen(true)}
      >
        Evolve
      </StyledButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
        size="800px"
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            width="800px"
            height="min-content"
            minHeight="min-content"
            mt="1rem !important"
          >
            <AlertDialogHeader fontWeight="bold" size="3xl" pb="0">
              <Text fontSize="26px">Evolve to Gen {data?.maxGen}</Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack spacing="5">
                <Text>
                  In order to evolve you are required to burn the squares. Once
                  the transaction has been approved in WavesKeeper,{' '}
                  <strong>the squares are gone forever.</strong> After the burn
                  transations have been processed we will mint you a brand new
                  Gen 3 asset and it will be automatically added to your wallet.
                </Text>
                <HStack justifyContent="flex-start" width="100%">
                  <Box
                    display="grid"
                    gridTemplateColumns={`repeat(auto-fit, ${imageWidth}px)`}
                    width={EVOLVE_SQUARE_WIDTH}
                  >
                    {data?.evolveCoords
                      .sort(
                        ({ index: aIndex }, { index: bIndex }) =>
                          (aIndex ?? 0) - (bIndex ?? 0),
                      )
                      .map(({ id, image, status }) => (
                        <Box key={id}>
                          <NftImage
                            id={id}
                            image={getDisplayImage(image)}
                            status={status}
                          />
                        </Box>
                      ))}
                  </Box>
                  <Box p="8">
                    <BsArrowRight size="40px" />
                  </Box>
                  <Box backgroundColor={`#${evolveBackgroundColor}`}>
                    <img
                      width={EVOLVE_SQUARE_WIDTH}
                      src={evolvePlaceholders[maxGen - 1]}
                      alt="Evolve preview"
                    />
                  </Box>
                </HStack>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <StyledButton
                leftIcon={<GiGrowth />}
                onClick={handleEvolveClick}
                ml={3}
              >
                Evolve
              </StyledButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EvolveButton;
