export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const WAVES_ENDPOINT = process.env.REACT_APP_WAVE_NODE;
export const WEBSOCKET_ENDPOINT =
  process.env?.REACT_APP_WS_ENDPOINT ??
  'wss://9zom6xrpda.execute-api.us-east-1.amazonaws.com/dev';
export const WAVES_DECIMAL = 10 ** 8;
export const WAVELET = 'WAVELET';

export const AVAILABLE_SQUARES_ENDPOINT = '/squares/available';
export const CAN_EVOLVE_ENDPOINT = '/squares/can-evolve';
export const LATEST_PURCHASES_ENDPOINT = '/squares/latest-purchases';
export const RANDOM_SQUARE_ENDPOINT = '/squares/random';
export const STATUS_ENDPOINT = '/squares/status';
export const SQUARE_BY_OWNER = '/squares/by-owner';
export const SQUARE_META_ENDPOINT = '/squares/meta';
export const TRANSACTIONS_ENDPOINT = '/transactions';
export const TRANSACTION_QUERY_KEY = 'getTransactions';
export const UPDATE_FROM_BLOCKCHAIN_ENDPOINT = '/update-from-blockchain';

export const ACTIVE = 'active' as const;
export const AVAILABLE = 'available' as const;
export const BURNED = 'burned' as const;
export const BLOCKCHAIN = 'blockchain' as const;
export const BUSY = 'busy' as const;
export const PURCHASING = 'purchasing' as const;
