import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAvailableSquares } from '../../../services/api';
import useWaves from '../../../hooks/crypto/useWaves';
import format from 'date-fns/format';

const ls = require('local-storage');

const PREVIOUS_GIFTS = 'previous-gifts';

type GiftedSquares = {
  date: string;
  notes?: string;
  userAddress: string;
  x: string;
  y: string;
};

const getFromLocalStorage = () => {
  try {
    return JSON.parse(ls.get(PREVIOUS_GIFTS));
  } catch (e) {
    return [];
  }
};

const setInLocalStorage = (data: any) => {
  ls.set(PREVIOUS_GIFTS, JSON.stringify(data));
};

const AdminGiftSquares = () => {
  const { giftSquare } = useWaves();
  const [x, setX] = useState<string>('');
  const [y, setY] = useState<string>('');
  const [userAddress, setUserAddress] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [previousGifts, setPreviousGifts] = useState<GiftedSquares[]>(
    getFromLocalStorage(),
  );
  const [randomLoading, setRandomLoading] = useState<boolean>(false);
  const [invokeLoading, setInvokeLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setInLocalStorage(previousGifts);
  }, [previousGifts]);

  const handleGetRandom = async () => {
    setRandomLoading(true);
    const squares = await getAvailableSquares();
    setRandomLoading(false);

    const square = squares[Math.round(Math.random() * squares.length - 1)];

    if (square) {
      setX(square?.x?.toString() ?? x ?? '');
      setY(square?.y?.toString() ?? y ?? '');
    }
  };

  const handleSubmit = async () => {
    if (x && y && userAddress) {
      setInvokeLoading(true);
      try {
        await giftSquare({ x, y, userAddress });
        setPreviousGifts([
          {
            date: format(new Date(), 'MMMM do HH:mm'),
            x,
            y,
            userAddress,
            notes,
          },
          ...(getFromLocalStorage() ?? []),
        ]);
        setInvokeLoading(false);
        setX('');
        setY('');
        setErrorMessage('');
      } catch (e) {
        // @ts-ignore
        setErrorMessage(e.toString());
        setInvokeLoading(false);
      }
    }
  };

  return (
    <VStack spacing="5" alignItems="flex-start">
      <HStack width="100%" spacing="5">
        <InputGroup>
          <InputLeftAddon children="x" />
          <Input
            type="text"
            placeholder="x coordinate"
            value={x}
            onChange={evt => setX(evt.currentTarget.value)}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="y" />
          <Input
            type="text"
            placeholder="y coordinate"
            value={y}
            onChange={evt => setY(evt.currentTarget.value)}
          />
        </InputGroup>
        <Button
          isLoading={randomLoading}
          colorScheme="blue"
          minWidth="150px"
          onClick={handleGetRandom}
        >
          Get random
        </Button>
      </HStack>
      <InputGroup>
        <InputLeftAddon children="Send to" />
        <Input
          type="text"
          placeholder="3MpFtcdhaB66NB38tW2APSiaVqaAoWoPvRJ"
          value={userAddress}
          onChange={evt => setUserAddress(evt.currentTarget.value)}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon children="Notes" />
        <Input
          type="text"
          placeholder="Username, campaign, anything to track who this person is"
          value={notes}
          onChange={evt => setNotes(evt.currentTarget.value)}
        />
      </InputGroup>
      <Box>
        <Button
          isLoading={invokeLoading}
          colorScheme="blue"
          width="250px"
          onClick={handleSubmit}
        >
          Gift
        </Button>
      </Box>
      {errorMessage && <Text color="red.500">{errorMessage}</Text>}
      {previousGifts?.length && (
        <TableContainer width="100%">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>X</Th>
                <Th>Y</Th>
                <Th>To</Th>
                <Th>Date</Th>
                <Th>Notes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {previousGifts.map(p => (
                <Tr key={p.date}>
                  <Td>{p.x}</Td>
                  <Td>{p.y}</Td>
                  <Td>{p.userAddress}</Td>
                  <Td>{p.date}</Td>
                  <Td>{p.notes}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </VStack>
  );
};

export default AdminGiftSquares;
