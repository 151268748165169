import React, { useState } from 'react';
import {
  Box,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import useWaves from 'hooks/crypto/useWaves';
import { WavesIcon } from '../../Icons';
import LoadingButton from '../../Global/LoadingButton';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../../redux/slices/nft';
import { isSquareLoading } from '../../../helpers/blockchain';
import { RiInformationFill } from 'react-icons/ri';
import { activeStates } from '../../../constants';

type Props = {
  assetId: string;
};

const PutForSale = ({ assetId }: Props) => {
  const { putUpForSale } = useWaves();
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { coords, status } = useSelector(getNftDataByActiveId);

  const isDisabled =
    !amount.trim() || isNaN(parseFloat(amount)) || parseFloat(amount) < 1;

  const handleSellClick = async () => {
    setLoading(true);
    if (!isDisabled && coords) {
      await putUpForSale({ assetId, amount, coords });
    }
    setLoading(false);
  };

  const handleKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.which === 13) {
      handleSellClick();
    }
  };

  return (
    <Box position="relative">
      <VStack alignItems="flex-start" spacing="4" position="relative" p="0">
        <HStack>
          <Heading size="md">Put up for sale</Heading>
          <Box position="relative">
            <Tooltip
              hasArrow
              label="This is the amount of waves you will receive minus a 2.5% transaction fee"
              bg="gray.300"
              color="black"
            >
              <span>
                <RiInformationFill />
              </span>
            </Tooltip>
          </Box>
        </HStack>
        <HStack>
          {(!status || activeStates.includes(status)) && (
            <>
              <InputGroup size="md" flex="1">
                <InputLeftElement
                  pointerEvents="none"
                  children={<WavesIcon size={16} />}
                />
                <Input
                  disabled={isSquareLoading(status)}
                  onChange={evt => setAmount(evt.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="2.5"
                  type="number"
                  value={amount}
                />
              </InputGroup>
            </>
          )}
          <LoadingButton
            disabled={isDisabled}
            loading={loading}
            size="md"
            status={status}
            onClick={handleSellClick}
          >
            Sell
          </LoadingButton>
        </HStack>
      </VStack>
    </Box>
  );
};

export default PutForSale;
