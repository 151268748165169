import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import theme from 'theme/custom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const globalStyles = css`
  @font-face {
    font-family: 'deadly_advanceregular';
    src: url('/fonts/deadly_advance-webfont.woff2') format('woff2'),
      url('/fontsdeadly_advance-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'deadly_advanceitalic';
    src: url('/fonts/deadly_advance_italic-webfont.woff2') format('woff2'),
      url('/fonts/deadly_advance_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html,
  body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #1a202c;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  canvas {
    cursor: pointer;
  }

  * {
    &::-webkit-scrollbar {
      width: 12px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #2d3748; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1a202c; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid #2d3748; /* creates padding around scroll thumb */
    }
  }
`;

Sentry.init({
  dsn: 'https://53913a2f2e3543ceb8e3da492b278a0b@o1137243.ingest.sentry.io/6189575',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <>
      <Global styles={globalStyles} />
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </QueryClientProvider>
        </ChakraProvider>
      </Provider>
    </>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
