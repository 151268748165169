import React from 'react';
import { useSelector } from 'react-redux';
import { getUserAddress } from '../../../redux/slices/application';
import PutForSale from './PutForSale';
import ForSale from './ForSale';
import { getNftDataByActiveId } from '../../../redux/slices/nft';
import OwnerUpForSale from './OwnerUpForSale';
import { EmptyMessage, MessageWithButton } from '../../EmptyStates';
import useWaves from '../../../hooks/crypto/useWaves';

type Props = {
  assetId: string;
};

const PurchasedActions = ({ assetId }: Props) => {
  const { authenticate } = useWaves();
  const { coords, owner, saleAmount } = useSelector(getNftDataByActiveId);
  const userAddress = useSelector(getUserAddress);

  const isForSale = saleAmount && saleAmount > 0 && assetId;

  if (!userAddress) {
    return (
      <MessageWithButton
        message="Not authenticated with Waves Keeper"
        buttonLabel="Authenticate"
        onClick={authenticate}
      />
    );
  }

  if (userAddress === owner) {
    if (isForSale) {
      return <OwnerUpForSale />;
    }
    if (assetId && coords) {
      return <PutForSale assetId={assetId} />;
    }
  }

  if (isForSale) {
    return <ForSale />;
  }

  return <EmptyMessage height="30px" message="Not for sale" />;
};

export default PurchasedActions;
