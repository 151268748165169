import { useContext, useEffect, useMemo, useState } from 'react';
import HTMLGrid, { TOP_LEFT } from '../Gallery/HTMLGrid';
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import LoadingButton from '../Global/LoadingButton';
import { WavesTokenDisplay } from '../Waves';
import React from 'react';
import styled from '@emotion/styled';
import { vector2D } from '../../hooks/canvas/util';
import { useSelector } from 'react-redux';
import { getNftDataByActiveId } from '../../redux/slices/nft';
import { selectDialog } from '../../redux/slices/dialog';
import useSquarePathfinding from '../../hooks/useSquarePathfinding';
import { DialogContext } from '../Dialogs/DialogContext';
import { useWavesPrice } from '../../services/blockchain';
import { activeStates, purchasableStates } from '../../constants';
import { MdOutlineInfo } from 'react-icons/md';

const itemSize = 100;

const ClaimButton = styled.button<{ isSelected: boolean }>`
  transition: all 0.3s ease-out;
  cursor: pointer;
  display: block;
  width: ${itemSize + 8}px;
  height: ${itemSize + 8}px;
  border: solid 4px transparent;

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: #cc0000;
    transform: scale(1.1);
  `}

  * {
    pointer-events: none;
  }
`;

const ClaimOption = styled(VStack)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const OptionDisplay = styled(Box)`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const descriptions = [
  'Claim a single square and dip your toes in.',
  'Claim 4 squares and instantly get a second generation.',
  'Claim 16 squares and skip your childhood.',
];

type Props = {
  onPurchase?: (coords: string[], userText?: string) => void;
};

const Options = ({ onPurchase }: Props) => {
  const { data: currentWavesPrice = 1 } = useWavesPrice();
  const { setSelectionBounds, selectedSize, setSelectedSize } =
    useContext(DialogContext);
  const [selectedOption, setSelectedOption] = useState<number>(
    selectedSize ?? 1,
  );
  const [userText, setUserText] = useState('');
  const { activeId } = useSelector(selectDialog);
  const { fitCoordInSquare } = useSquarePathfinding();
  const [purchasing, setPurchasing] = useState<boolean>(false);

  const {
    x: col = 0,
    y: row = 0,
    coords,
    status,
  } = useSelector(getNftDataByActiveId);

  const squareData = useMemo(
    () =>
      fitCoordInSquare({
        coord: vector2D(col, row),
        size: vector2D(1, 1),
      }),
    [coords, col, row],
  );

  const twoSquareData = useMemo(
    () =>
      fitCoordInSquare({
        coord: vector2D(col, row),
        size: vector2D(2, 2),
      }),
    [coords, col, row],
  );

  const threeSquareData = useMemo(
    () =>
      fitCoordInSquare({
        coord: vector2D(col, row),
        size: vector2D(4, 4),
      }),
    [coords, col, row],
  );

  const options = [squareData, twoSquareData, threeSquareData];
  const totalOptions = options.filter(Boolean).length;
  const selectionOptionData = options?.[selectedOption] ?? squareData;

  useEffect(() => {
    setPurchasing(false);
  }, [activeId]);

  useEffect(() => {
    setSelectionBounds?.(selectionOptionData?.bounds);
  }, [selectionOptionData]);

  const handlePurchase = async () => {
    if (selectedOption === 2 && selectionOptionData?.coords) {
      const c = selectionOptionData.coords;
      const tlEvolve = [c[0], c[1], c[4], c[5]];
      const trEvolve = [c[2], c[3], c[6], c[7]];
      const blEvolve = [c[8], c[9], c[12], c[13]];
      const brEvolve = [c[10], c[11], c[14], c[15]];
      const orderedCoords = [
        ...tlEvolve,
        ...brEvolve,
        ...trEvolve,
        ...blEvolve,
      ];
      onPurchase?.(orderedCoords, userText);
    } else {
      onPurchase?.(selectionOptionData?.coords ?? [], userText);
    }
  };

  const handleOptionClick = (index: number) => {
    setSelectedOption(index);
    setSelectedSize?.(index);
  };

  const totalSalePrice = selectionOptionData?.saleTotal ?? 0;

  return (
    <ClaimOption>
      <VStack alignItems="flex-start" spacing="6">
        <Text>{descriptions[selectedOption]}</Text>
        <HStack>
          <LoadingButton
            loading={purchasing}
            status={status}
            onClick={handlePurchase}
            rightIcon={
              <WavesTokenDisplay
                waves={totalSalePrice}
                showConversion={false}
              />
            }
          >
            Claim for
          </LoadingButton>
          {!status ||
            (activeStates.includes(status) && (
              <Text color="gray.500">
                (~${(totalSalePrice * currentWavesPrice).toFixed(2)} USD)
              </Text>
            ))}
        </HStack>
      </VStack>
      {purchasableStates.includes(status!) && (
        <VStack spacing="5">
          <HStack position="relative" width="100%">
            <InputGroup flex="1">
              <InputLeftAddon children="@" />
              <Input
                type="text"
                placeholder="Your social handle"
                value={userText}
                onChange={evt => setUserText(evt.currentTarget.value)}
              />
            </InputGroup>
            <Tooltip
              label="This is to help people get in contact with you on our Discord or to show off your gang on the grid. This will be associated to all the squares you own on the grid and you can only set this value on purchase of a square and will be stored on the blockchain"
              fontSize="md"
            >
              <Box>
                <MdOutlineInfo />
              </Box>
            </Tooltip>
          </HStack>
          {totalOptions > 1 && (
            <OptionDisplay>
              <ClaimButton
                isSelected={selectedOption === 0}
                onClick={() => handleOptionClick(0)}
              >
                <HTMLGrid
                  coords={vector2D(col, row)}
                  highlightedCoord={` `}
                  orient={TOP_LEFT}
                  itemSize={itemSize}
                  xSize={1}
                  ySize={1}
                />
              </ClaimButton>
              {twoSquareData && (
                <ClaimButton
                  isSelected={selectedOption === 1}
                  onClick={() => handleOptionClick(1)}
                >
                  <HTMLGrid
                    coords={twoSquareData?.topLeft ?? vector2D(col, row)}
                    highlightedCoord={` `}
                    orient={TOP_LEFT}
                    itemSize={itemSize / 2}
                    xSize={2}
                    ySize={2}
                  />
                </ClaimButton>
              )}
              {threeSquareData && (
                <ClaimButton
                  isSelected={selectedOption === 2}
                  onClick={() => handleOptionClick(2)}
                >
                  <HTMLGrid
                    coords={threeSquareData?.topLeft ?? vector2D(col, row)}
                    highlightedCoord={` `}
                    orient={TOP_LEFT}
                    itemSize={itemSize / 4}
                    xSize={4}
                    ySize={4}
                  />
                </ClaimButton>
              )}
            </OptionDisplay>
          )}
        </VStack>
      )}
    </ClaimOption>
  );
};

export default Options;
