import evolve1 from '../images/evolve-gen-1.png';
import evolve2 from '../images/evolve-gen-2.png';
import evolve3 from '../images/evolve-gen-3.png';
import { SquareStatus } from '../hooks/crypto/types';
import { ACTIVE, AVAILABLE } from './api';
import { EVOLVE } from './sockets';

export const COMMON = 'common' as const;
export const RARE = 'rare' as const;
export const EPIC = 'epic' as const;
export const LEGENDARY = 'legendary' as const;

export const simpleColorSchemes = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
];

export const colorSchemes = [
  ...simpleColorSchemes,
  'linkedin',
  'facebook',
  'messenger',
  'whatsapp',
  'twitter',
  'telegram',
];

export const evolvePlaceholders = [evolve1, evolve2, evolve3];

export const activeStates: SquareStatus[] = [AVAILABLE, ACTIVE, EVOLVE];
export const purchasableStates: SquareStatus[] = [AVAILABLE, ACTIVE];
