import { SquareStatus, WavesAsset } from '../hooks/crypto/types';
import {
  ACTIVE,
  AVAILABLE,
  BLOCKCHAIN,
  BUSY,
  EVOLVE,
  PURCHASING,
  WAVES_DECIMAL,
} from '../constants';
import { NftData } from '../types';

const squareStatusLabels: Partial<Record<SquareStatus, string>> = {
  // [ACTIVE]: 'Available',
  [AVAILABLE]: 'Available',
  [BLOCKCHAIN]: 'Waiting for blockchain',
  [BUSY]: 'Busy',
  [EVOLVE]: 'Evolving',
  [PURCHASING]: 'Purchasing',
};

export const wavesAssetToData = ({ items }: { items?: WavesAsset[] }) => {
  if (!items) {
    return [];
  }
  return items.reduce<NftData[]>((acc, { assetId, name, description }) => {
    const [x, rest] = name.split(',');
    if (!rest) {
      return acc;
    }
    const [y] = rest.split(' ');
    acc.push({
      coords: `${x},${y}`,
      id: name,
      image: description,
      x: parseInt(x),
      y: parseInt(y),
    });
    return acc;
  }, []);
};

export const isSquareLoading = (status?: SquareStatus) =>
  status && status !== ACTIVE && status !== AVAILABLE;
export const getStatusMessage = (
  status?: SquareStatus,
  defaultValue: string = BUSY,
) => {
  if (!status) {
    return defaultValue;
  }

  return squareStatusLabels?.[status] ?? defaultValue;
};

export const normalizeWaves = (balance?: number | string): number => {
  if (!balance) {
    return 0;
  }
  const waves = parseFloat(balance.toString());
  if (waves < 100_000) {
    return waves;
  }
  return getReadableWaves(waves);
};
export const getReadableWaves = (balance: number) => balance / WAVES_DECIMAL;
export const getFullWaves = (balance: number) =>
  parseInt((balance * WAVES_DECIMAL).toString());
