import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, DialogState } from 'types';

const initialState: DialogState = {
  isOpen: false,
  activeId: null,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state: DialogState, action: PayloadAction<number>) => {
      state.isOpen = true;
      state.activeId = action.payload;
    },
    closeDialog: (state: DialogState) => {
      state.isOpen = false;
      state.activeId = null;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectDialog = (state: AppState) => state.dialog;

export default dialogSlice.reducer;
