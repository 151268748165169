import { useSelector } from 'react-redux';
import { getNftDictData } from '../redux/slices/nft';
import { vector2D, Vector2D } from './canvas/util';
import { BASE_SQUARE_PRICE } from '../constants';
import { normalizeWaves } from '../helpers/blockchain';

type FitCoordInSquare = {
  coord: Vector2D;
  size: Vector2D;
};

type MoveInDirection = {
  coord: Vector2D;
  direction: Vector2D;
  size: Vector2D;
  topLeft: Vector2D;
};

export type FitCoordInSquareReturn = {
  bounds?: Vector2D[];
  coords: string[];
  initialCoord: Vector2D;
  saleTotal: number;
  topLeft: Vector2D;
} | null;

type AllMatchInArea = {
  size: Vector2D;
  topLeft: Vector2D;
};

type Bounds = Vector2D;

type IsInBounds = {
  bounds: Bounds[];
  point: Vector2D;
};

const useSquarePathfinding = () => {
  const nftDict = useSelector(getNftDictData);

  const isInBounds = ({ bounds, point }: IsInBounds) => {
    const [{ x: sx, y: sy }, { x: ex, y: ey }] = bounds;
    const { x: px, y: py } = point;

    return px >= sx && px <= ex && py >= sy && py <= ey;
  };

  const allAvailableInArea = ({
    size,
    topLeft,
  }: AllMatchInArea): { foundCoords: string[]; saleTotal: number } | null => {
    const bounds: string[] = [];
    let saleTotal = 0;
    for (let nextX = 0; nextX < size.x; nextX++) {
      for (let nextY = 0; nextY < size.y; nextY++) {
        const x = topLeft?.x + nextX;
        const y = topLeft?.y + nextY;
        const currentCoord = `${x},${y}`;
        const { owner, saleAmount } = nftDict?.[currentCoord] ?? {};

        if (owner && owner !== 'null' && !saleAmount) {
          return null;
        }

        saleTotal += saleAmount
          ? normalizeWaves(saleAmount)
          : BASE_SQUARE_PRICE;

        bounds.push(currentCoord);
      }
    }

    return {
      foundCoords: bounds,
      saleTotal,
    };
  };

  const moveInDirection = ({
    coord: squareCoord,
    direction,
    size,
    topLeft,
  }: MoveInDirection): FitCoordInSquareReturn => {
    const resolvedTopLeft = topLeft ?? squareCoord;
    let currentTopLeft = { ...resolvedTopLeft };
    let bounds: Bounds[] = [
      { ...resolvedTopLeft },
      vector2D(
        currentTopLeft.x + (size.x - 1),
        currentTopLeft.y + (size.y - 1),
      ),
    ];

    while (isInBounds({ bounds, point: squareCoord })) {
      const available = allAvailableInArea({
        size,
        topLeft: currentTopLeft,
      });
      if (available) {
        const { foundCoords, saleTotal } = available;
        return {
          bounds,
          coords: foundCoords,
          initialCoord: squareCoord,
          saleTotal,
          topLeft: currentTopLeft,
        };
      }

      currentTopLeft = vector2D(
        currentTopLeft.x + direction.x,
        currentTopLeft.y + direction.y,
      );
      bounds = [
        { ...currentTopLeft },
        vector2D(
          currentTopLeft.x + (size.x - 1),
          currentTopLeft.y + (size.y - 1),
        ),
      ];
    }

    return null;
  };

  const fitCoordInSquare = ({
    coord: squareCoord,
    size,
  }: FitCoordInSquare): FitCoordInSquareReturn => {
    const moveLeftDirection = vector2D(-1, 0);
    const moveUpDirection = vector2D(0, -1);
    const moveRightDirection = vector2D(1, 0);

    const initialTry = allAvailableInArea({
      size,
      topLeft: { ...squareCoord },
    });

    if (initialTry) {
      const { foundCoords, saleTotal } = initialTry;
      return {
        bounds: [
          squareCoord,
          vector2D(squareCoord.x + (size.x - 1), squareCoord.y + (size.y - 1)),
        ],
        coords: foundCoords,
        saleTotal,
        topLeft: squareCoord,
        initialCoord: squareCoord,
      };
    }

    const moveLeftTry = moveInDirection({
      coord: { ...squareCoord },
      direction: moveLeftDirection,
      topLeft: { ...squareCoord },
      size,
    });

    if (moveLeftTry) {
      return moveLeftTry;
    }

    const secondTryTopLeft = vector2D(
      squareCoord.x - (size.x - 1),
      squareCoord.y,
    );
    const moveUpTry = moveInDirection({
      coord: squareCoord,
      direction: moveUpDirection,
      topLeft: secondTryTopLeft,
      size,
    });

    if (moveUpTry) {
      return moveUpTry;
    }

    const thirdTryTopLeft = vector2D(
      squareCoord.x - (size.x - 1),
      squareCoord.y - (size.y - 1),
    );
    const moveRightTry = moveInDirection({
      coord: squareCoord,
      direction: moveRightDirection,
      topLeft: thirdTryTopLeft,
      size,
    });

    return moveRightTry;
  };

  return {
    fitCoordInSquare,
  };
};

export default useSquarePathfinding;
